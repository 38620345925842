import { useState } from "react"

import { ToastContainer, toast } from "react-toastify"

import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';

import styles from './styles.module.css'

import { authApi } from "../../services/api/api"
import useAuth from "../../hooks/useAuth"

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Comment from "../comment"
import TextEditor from "../textEditor";

export default function Post({post, handleSetPostComment, atualizar, setAtualizar}:any){

    const api = authApi()

    const { user, theme }  = useAuth()

    const [likes, setLikes] = useState(post.likes)

    const [liked, setLiked] = useState(post.like ? true : false)

    const [text, setText] = useState(post.description ?? '')

    const [textComment, setTextComment] = useState('')

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const [open1, setOpen1] = useState(false);

    const handleClickOpen1 = () => {
      setOpen1(true);
    };
  
    const handleClose1 = () => {
      setOpen1(false);
    };

    const [open2, setOpen2] = useState(false);

    const handleClickOpen2 = () => {
      setOpen2(true);
    };
  
    const handleClose2 = () => {
      setOpen2(false);
    };


    async function setComment(){

        if(text === ''){
            toast.error('Preencha o comentário!',{theme:'dark',toastId: 'valid_text'})
            return
        }

        toast.loading('Carregando...',{theme:'dark',toastId: 'load_comment'})

        if(user){

            const data = {
                'user': user.id,
                'post': post.id,
                'text': textComment
            } 

            await handleSetPostComment(data)
        }
        handleClose()
        toast.dismiss('load_comment')
    }
    
    async function handleLikePost(){


        if(user){
            const response = await api.likePost(user.id, post.id)
           
            if(response === 200){
               
                setLikes(likes + 1)
                setLiked(true)
            }else{
                setLikes(likes - 1)
                setLiked(false)
            }
        }
    }

    
    async function handleDelete(){
        const response = await api.destroyPost(post.id)
     

        if(response == 200){
            toast.success('Publicação excluida com sucesso.',{theme:'dark',toastId: 'deleteSuccsess'})
            setAtualizar(!atualizar)
            handleClose1()
            return
        }
        handleClose1()
        toast.info('Não foi possível excluir a publicação.',{theme:'dark',toastId: 'errorDelete'})
    }

    async function handleUpdate(){

         const data = {
            id: post.id,
            text: text
         }

         toast.loading('Carregando.',{theme:'dark',toastId: 'loadUpdate'})

         const response = await api.updatePost(data)

         if(response == 200){
            toast.dismiss('loadUpdate')
            setAtualizar(!atualizar)
            handleClose2()
         }else{
            toast.dismiss('loadUpdate')
            toast.error('Não foi possivel atualizar a indicação.',{theme:'dark',toastId: 'erroUpdate'})
         }

    }


    return(
        <div className="w-full  flex justify-center flex-col dark:bg-gray-700 p-4 my-2 m-auto rounded bg-gray-300">
            <div className=" flex items-center gap-2">
                { post.user.img ? 
                <img src={post.user.img} alt="foto do usuario"  width={60} height={60} className="bg-auto max-h-[60px] rounded-full border-2 border-white dark:border-blue-700"/>
                :
                <img src={require("../../assets/logos/logo-icon.png")} alt="teste"  width={60} height={60} className="bg-auto max-h-[60px] bg-white rounded-full border-2 border-white dark:border-blue-700"/>
                }
                <h1 className="text-sm text-blue-950 dark:text-white "><strong>{post.user.name}</strong> <small>Fez uma publicação. <span className="font-bold">{Intl.DateTimeFormat('pt-BR').format(new Date(post.created_at))}</span></small></h1>
                {post.user_id == user.id ? 
                <div className="flex gap-2 ml-2"><button className="text-red-500" onClick={handleClickOpen1}><DeleteForeverIcon fontSize="small"/></button>
                <button className="text-blue-800 dark:text-white" onClick={handleClickOpen2}><EditIcon fontSize="small"/></button></div> 
                : ''}
            </div> 
            <div className="flex mb-2 w-full">
                <div className="">
                    <div className="bg-gray-400" style={{width:'2px', height:'80px', margin:'5px 25px 0px 25px'}}></div>
                    <div className="bg-gray-400" style={{width:'50px', height:'2px', marginLeft:'25px', marginRight:'5px'}}></div>
                    <div className="mt-9 ml-6">
                        <button className="flex items-center gap-1" onClick={handleLikePost}>
                            <small className="dark:text-white text-black mt-1">{likes}</small>
                            <div className="text-blue-700 dark:text-white">
                                {liked ? <ThumbUpAltIcon/> : <ThumbUpOffAltIcon/>}
                            </div>
                        </button>
                    </div>
                </div>
                <div className={`flex gap-2 w-full ${styles.displayColumn}`}>
                    <div>
                        <img src={post.book?.img ?? post.img} alt="capa do livro" style={{width:'100px', height:'150px'}}/>
                        <Button onClick={handleClickOpen} variant="text" size='small'>Comentar</Button>
                    </div>
                </div>
            </div>

            <div className="w-full mb-4 sm:pl-4" >
                <p className="text-blue-950 flex flex-col font-semibold dark:text-white" style={{textTransform:'capitalize'}}>{post.book?.title ?? post.title} <small>{post.book?.autor}</small></p>
                
                <div className={`overflow-auto ${styles.scroll} w-full  p-1 bg-gray-100 rounded dark:bg-gray-600`} style={{maxHeight:'600px'}}>
                    <div className="w-full dark:text-white " dangerouslySetInnerHTML={{__html: post.description}}></div>
                    {/* <p className="m-2 ">{post.description}</p> */}
                </div>
            </div>
       
            {post?.comment?.length > 0 && post.comment.map((item:any) => <Comment atualizar={atualizar} setAtualizar={setAtualizar} key={item.id} item={item} />)}

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Escreva seu comentário."}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {/* <textarea onChange={(e)=> setText(e.target.value)} className="bg-gray-100" style={{minWidth:'450px', minHeight:'120px'}}></textarea> */}
                    <TextEditor setText={setTextComment}/>
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={setComment} autoFocus>
                    Enviar
                </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={open1}
                onClose={handleClose1}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Excluir Post."}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <h1>Tem certeza que deseja excluir o post?</h1>
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleDelete} autoFocus color="error">
                    Deletar
                </Button>
                <Button onClick={handleClose1} autoFocus >
                    Cancelar
                </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={open2}
                onClose={handleClose2}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Atualizar Post."}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                <div>
                    {/* <textarea value={text} onChange={(e)=> setText(e.target.value)} className="bg-gray-100" style={{minWidth:'450px', minHeight:'120px'}}></textarea> */}
                    <TextEditor text={text} setText={setText}/>
                </div>
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleUpdate} autoFocus>
                    Atualizar
                </Button>
                <Button onClick={handleClose2} autoFocus >
                    Cancelar
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}