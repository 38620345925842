import styles from './styles.module.css'
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import { useState } from 'react'
import { authApi } from '../../services/api/api'
import useAuth from '../../hooks/useAuth'
import { toast } from "react-toastify"

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import TextEditor from '../textEditor';

export default function Comment({item, atualizar, setAtualizar}:any){
 
    const api = authApi()

    const { user }  = useAuth()

    const [likes, setLikes] = useState<number>(item.likes)

    const [liked, setLiked] = useState<boolean>(item.like ? true : false)

    const [text, setText] = useState<string>(item.description ?? '')


    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const [open1, setOpen1] = useState(false);

    const handleClickOpen1 = () => {
      setOpen1(true);
    };
  
    const handleClose1 = () => {
      setOpen1(false);
    };

    async function handleLikeComment(comment_id:number){


        if(user){
            const response = await api.likeCommet(user.id, comment_id)
            
            if(response === 201){
                setLikes((old) => old - 1)
                setLiked(false)
                return
            }
            setLikes((old) => old +1)
            setLiked(true)
        }
    }

    async function handleDelete(){

        const response = await api.deleteComment(item.id)

        if(response == 200){
            toast.success('Comentário excluído com sucesso.',{theme:'dark',toastId: 'deleteComment'})
            setAtualizar(!atualizar)
            handleClose()
            return
        }
        toast.error('Não foi possível excluir o comentário.',{theme:'dark',toastId: 'errorComment'})
        handleClose()
        return
    }

    async function handleUpdate(){

        const data = {
            'comment': item.id,
            'text': text
        }
        
        const response = await api.updateComment(data)

        if(response == 200){
            toast.success('Comentário atualizado com sucesso.',{theme:'dark',toastId: 'editComment'})
            setAtualizar(!atualizar)
            handleClose1()
            return
        }
        toast.error('Não foi possível editar o comentário.',{theme:'dark',toastId: 'errorComment'})
        handleClose1()
        return
    }

    return(
        <div className="flex justify-between gap-4 mb-4 dark:text-white w-full " >
                <div className=" flex flex-col items-center " style={{maxWidth:'50px', maxHeight:'50px', minWidth:'50px'}}>
                    <img src={item.user.img} alt="foto do usuario"  width={50} className="rounded-full border-2 border-white dark:border-blue-700"/>
                    <button onClick={() => handleLikeComment(item.id)} className="flex justify-center items-center gap-1 mt-4 ">
                        <span className='text-black dark:text-white mt-1'>{likes}</span>
                        <span className="text-blue-700 dark:text-white">{liked ? <ThumbUpAltIcon/> : <ThumbUpOffAltIcon/>}</span>
                    </button>
                </div>
                <div className={` bg-gray-100 dark:bg-gray-600 rounded w-full`} style={{ maxHeight:'100px', minHeight:'100px'}}>
                    <p className="text-blue-950 font-semibold ml-2 flex dark:text-white">{item.user.name} 
                    
                    {item.user_id == user.id ? 
                        <div className="flex gap-2 ml-2"><button className="text-red-500" onClick={handleClickOpen}><DeleteForeverIcon fontSize="small"/></button>
                        <button className="text-blue-800 dark:text-white" onClick={handleClickOpen1}><EditIcon fontSize="small"/></button></div> 
                        : ''}
                    </p>
                   <hr className='border-gray-500'/>
                    <div className={`overflow-auto text-left ${styles.scroll} w-full bg-gray-100 dark:bg-gray-600 rounded p-2`} style={{ maxHeight:'100px'}}>
                        {/* <p className="text-left text-black ml-2 dark:text-white">{item.description}</p> */}
                        <div className="w-full" dangerouslySetInnerHTML={{__html: item.description}}></div>
                    </div>
                </div>

                <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Escreva seu comentário."}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <h1>Tem certeza que deseja excluir o comentario?</h1>
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleDelete} autoFocus color="error">
                    Deletar
                </Button>
                <Button onClick={handleClose} autoFocus >
                    Cancelar
                </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={open1}
                onClose={handleClose1}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Escreva seu comentário."}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {/* <textarea className='w-96 h-40' onChange={(e) => setText(e.target.value)} value={text}></textarea> */}
                    <TextEditor text={text} setText={setText}/>
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleUpdate} autoFocus>
                    Atualizar
                </Button>
                <Button onClick={handleClose1} autoFocus >
                    Cancelar
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}