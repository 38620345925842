import Footer from "../../components/footer"
import Menu from "../../components/menu"
import useAuth from "../../hooks/useAuth"

import { useQuery, keepPreviousData } from "@tanstack/react-query"
import { authApi } from "../../services/api/api"

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import moment from "moment"
import MiniBook from "../../components/miniBook"
import { useLocation } from "react-router-dom"


import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useState } from "react"
import ActualBook from "../../components/skeletons/actualBook"
import SkeletonMiniBook from "../../components/skeletons/miniBook"
import TextEditor from "../../components/textEditor"


export default function MeusLivros(){

    const { user, theme }  = useAuth()

    const api = authApi()

    const att = useLocation()

    const [attBooks, setAttBooks] = useState(null)

    const { data, isPending } = useQuery(
        {
            queryKey: ['myBooks', att.state, user, attBooks], 
            queryFn: () => api.myBooks(user?.id), 
            // placeholderData:keepPreviousData, 
            // staleTime: 5 * 60 * 1000
        }
    )
    
    const openPost = moment().isBetween(user?.start, user?.end)

    const start = moment(data?.[0]?.start).format('DD/MM/YYYY')

    const end = moment(data?.[0]?.end).format('DD/MM/YYYY')

    const dataAtual = moment().format('DD/MM/YYYY')

    const dataAtual1 = moment().format('YYYY/MM/DD')


    //diferença entre a data inicial e a data final em dias
    const diff = moment(end,"DD/MM/YYYY").diff(moment(dataAtual,"DD/MM/YYYY"));
    //dias restantes
    const dias = moment.duration(diff).asDays();

    const diasPassados = 30 - dias

    const taxa = 100 / 30

    //porcentagem da barra
    const percent = diasPassados * taxa

    const [open, setOpen] = useState(false);

    const [id, setId] = useState();

    const handleClickOpen = (id) => {

        setId(id)

        setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const [text, setText] = useState('');


    async function handleSetPost(){
        toast.info('Carregando.',{theme:'dark',toastId: 'loadPost'})
        const dados = {
            user:user?.id,
            text:text,
            book:id
        }

        const response = await api.setPost(dados)
        
        if(response === 200){
            toast.success('Post Criado com sucesso!.',{theme:'dark',toastId: 'successPost'})
            toast.dismiss('loadPost')
            handleClose()
        }else{
            toast.error('Houve algum erro, tente novamente mais tarde.',{theme:'dark',toastId: 'errorPost'})
        }
    }

    async function handleDismiss(id:number){
        toast.loading('Carregando.',{theme:'dark',toastId: 'dismissLoad'})
        const response = await api.dismissBooksUser(id)
        console.log(response)
        if(response == 200){
            toast.success('Livro entregue com sucesso.',{theme:'dark',toastId: 'dismiss'})
            toast.dismiss('dismissLoad')
            setAttBooks((old) => !old)
            return
        }
        toast.dismiss('dismissLoad')
        toast.error('Não foi possivel entregar o livro.',{theme:'dark',toastId: 'dismiss-error'})

    }
    
    return(
        <div className={`main w-screen  bg-gray-100 ${theme} overflow-x-hidden`}>
            <Menu/>
            <div className="content w-screen min-h-screen flex justify-cente dark:bg-slate-800 bg-gray-100">
                <div className="w-full sm:w-4/5 flex justify-center m-auto  p-8">
                    <div className="w-full flex justify-center">
                        <div className="w-full">
                            <div className="w-full">
                                <h4 className="text-dark dark:text-white">Último Livro Reservado</h4>
                                <hr className="border-gray-200 border-1 rounded"/>
                                <div className="w-full flex justify-center my-4">
                                    {data?.[0] ? (
                                    <div className="flex dark:bg-gray-600 bg-white p-6 rounded shadow gap-4 flex-col sm:flex-row items-center">
                                        <div>
                                            <img src={(data?.[0]?.books.img) ?? ''} alt="Imagem do livro" width={150} height={200}/>
                                        </div>
                                        <div className="flex flex-col justify-between">
                                            <div className="">
                                                <div>
                                                    <h1 className="text-black font-semibold text-lg overflow-auto" style={{textTransform:'capitalize', maxWidth:'700px'}}>{ data?.[0]?.books.title}</h1>
                                                    <small style={{textTransform:'capitalize'}}>{data?.[0]?.books.autor}</small>
                                                </div>
                                                <div className="mt-2 flex flex-col gap-2 w-full">
                                                    <h1>Retirado em: <strong>{start}</strong></h1>
                                                    { data?.[0].status !== 'Entregue' ?
                                                        <h1 className={`${moment(data?.[0]?.finaly).isBefore(moment(dataAtual1)) ? 'text-red-700' : '' }`}>Prazo para devolução: <strong>{end}</strong></h1>
                                                        : ''
                                                    }
                                                    <h1>Status: <strong>{data?.[0]?.status}</strong></h1>
                                                </div>
                                                {moment(data?.[0]?.finaly).isAfter(moment(dataAtual1)) && (
                                                    <div className="mt-3">
                                                        <span>{dias} Dias restantes</span>
                                                        <div className="w-full bg-gray-200 h-1 rounded my-2">
                                                            <div className="bg-orange-400 h-1 rounded" style={{width:`${percent}%`}}>

                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="flex justify-between gap-2">
                                            {data?.[0].status != 'Entregue' && openPost ? (<button onClick={() => handleClickOpen(data?.[0].book_id)} className="dark:bg-blue-900 dark:hover:bg-blue-700 bg-sky-700 hover:bg-sky-500 text-white rounded w-28">Publicar</button>) : ''}
                                            {data?.[0].status != 'Entregue' ? <button onClick={() => handleDismiss(data?.[0].id)} className="dark:bg-blue-900 dark:hover:bg-blue-700 bg-sky-700 hover:bg-sky-500 text-white rounded w-28">Devolver</button> : ''}
                                            </div>
                                        </div>
                                    </div>
                                    ) : isPending ? <ActualBook/> : <div><h1 className="text-black dark:text-white">Nenhum livro reservado</h1></div>}
                                    
                                </div>
                                <h4 className="text-dark dark:text-white">Histórico</h4>
                                <hr className="border-gray-200 border-1 rounded"/>
                                <div className="my-4 flex flex-wrap gap-3 justify-center w-full">
                                    {data?.length > 0 ? data?.map((item:any) => (<MiniBook handleDismiss={handleDismiss} openPost={openPost} key={item.id} item={item} handleClickOpen={handleClickOpen}/>)) : isPending ? <SkeletonMiniBook/> : <div><h1 className="text-black dark:text-white">Nenhum livro reservado</h1></div>}
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Publicar reflexão do livro."}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                   <div className='flex gap-2'>
                    <TextEditor text={text} setText={setText}/>
                        {/* <textarea onChange={(e) => setText(e.target.value)} style={{width:'600px', height:'300px'}} className="overflow-auto"></textarea> */}
                   </div>
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleSetPost} autoFocus>
                    Publicar
                </Button>
                </DialogActions>
            </Dialog>

            <ToastContainer/>
            {/* <span></span> */}
            {/* <Footer/> */}
        </div>
    )
}