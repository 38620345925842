import { useState } from 'react'

import styles from './styles.module.css'

import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import EditNoteIcon from '@mui/icons-material/EditNote';

import { useNavigate } from "react-router-dom";

import moment from 'moment';
import { authApi } from '../../services/api/api';
import useAuth from '../../hooks/useAuth';

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function Book( {data}:any ){

    const api = authApi()

    const { user } = useAuth()

    const [open, setOpen] = useState(false);


    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const [open1, setOpen1] = useState(false);

    const handleClickOpen1 = () => {
      setOpen1(true);
    };
  
    const handleClose1 = () => {
      setOpen1(false);
    };

    const [likes, setLike] = useState(data?.like ? true : false)

    const [rating, setRating] = useState(data?.rating)

    const start = moment().format('YYYY-MM-DD')

    const end = moment().add(30, 'days').format('YYYY-MM-DD')

    const navigate = useNavigate()

    const [summary, setSummary] = useState(0)

    async function handleSelect(){

        toast.info('Carregando.',{theme:'dark',toastId: 'load'})

        if(user){

            const response = await api.selectBook(data.id, user.id)
            
            console.log(response)
            if(response == 419){
                toast.error('Parece que você já reservou este livro...',{theme:'dark',toastId: 'erro_book'})
                toast.dismiss('load')
                return
            }

            if(response == 200){
                toast.success('Livro reservado com sucesso! Lembre-se de retirar o livro até as 19:00hrs',{theme:'dark',toastId: 'load'})

                navigate('/dashboard', {state:true})
            }else{
                toast.error('Ocorreu algum erro, tente novamente mais tarde.',{theme:'dark',toastId: 'error'})
            }
        }
        toast.dismiss('load')
    }

    async function handleLike(){


        if(user){

            const response = await api.likeBook(data.id, user.id)

            if(response === 200){
                setLike(true)
                setRating((old) => old + 1)
            }else{
                setLike(false)
                setRating((old) => old - 1)
            }
        }
    }

    function handleSummary(){
        setSummary((old) => old === 0 ? 1 : 0)
        console.log(data)
    }

    function handleIndication(){
        navigate('/indicacao', {state:data})
    }

    function handleUpdateBook(){
        navigate('/adicionar-livro', {state:data})
    }

    return (
        <div style={{fontFamily:'Montserrat'}} className={`flex justify-center items-center ${styles.card}`}>
            <div className={`flex  sm:justify-center ${styles.card}`}>
                <div className={`${styles.card} dark:bg-gray-700 dark:border-gray-700 bg-white flex justify-between p-2 items-center sm:flex-col rounded z-10 hover:shadow-2xl dark:hover:border-sky-500 border-2 border-white`}>
                    <div className={`flex sm:flex-col mr-1  justify-between`}>
                        <div className={`flex bg-gray-100 sm:m-auto ${styles.imgBook}`}>
                            <img src={data?.img} alt="Capa do livro" className='w-full cursor-pointer' onClick={handleClickOpen1}/>
                        </div>
                    </div>
                    <div className='mt-2 w-full justify-between flex flex-col h-full'>
                        <div className={`dark:text-white text-center p-1 flex flex-col max-h-24 sm:max-h-20 overflow-auto ${styles.scroll}`}>
                            <small style={{textTransform:'capitalize'}} className='font-semibold w-full sm:w-full'>{data?.title}</small>
                            <small style={{textTransform:'capitalize'}}>{data?.autor}</small>
                        </div>
                        <div className='flex w-full px-2 justify-between gap-2 pb-1'>
                            <div className='flex gap-2  sm:flex-row'>
                                {data?.free_exemplaries?.length > 0 ? 
                                    (<button onClick={handleClickOpen} className={`flex items-center rounded-lg p-1 bg-blue-900 text-white`}><small>Disponível</small></button>) :
                                    (<button className={`flex items-center rounded-lg p-1 text-white bg-red-500`}><small>Indiponível</small></button>)}
                                
                                <button onClick={()=> handleIndication()} className='flex items-center rounded-lg p-1 bg-blue-900 text-white'><small>Indicar</small></button>
                            </div>
                            <div className='flex items-end gap-1  border dark:white  p-1 rounded ' >
                                <button onClick={handleLike} className='flex gap-1 items-end'>
                                    <p className='text-blue-700 dark:text-white' style={{lineHeight:1.2}}>{rating}</p> 
                                    <span className='text-blue-700 dark:text-white'>{likes  ? <ThumbUpAltIcon/> : <ThumbUpOffAltIcon/>}</span>
                                </button>
                                {user.admin === 1 ? <button className='text-blue-700 dark:text-white' onClick={handleUpdateBook}><EditNoteIcon/></button> : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Agendar Livro."}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                   <div style={{fontFamily:'Montserrat'}} className='flex gap-2'>
                    <div style={{width:'150px'}}>
                        <img src={data?.img} alt="Capa do livro" className='w-full cursor-pointer'/>
                    </div>
                    <div>
                        <div className='flex flex-col gap-1'>
                            <label className='dark:text-white text-black'>Data de retirada</label>
                            <input type="date" className='rounded p-1 text-center' defaultValue={start} readOnly/>
                        </div>
                        <div className='flex flex-col gap-1'>
                            <label className='dark:text-white text-black'>Data de entrega</label>
                            <input type="date" className='rounded p-1 text-center' defaultValue={end} readOnly/>
                        </div>
                    </div>
                   </div>
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleSelect} autoFocus>
                    Reservar
                </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={open1}
                onClose={handleClose1}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-summary">
                {"Informações sobre o livro."}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-summary">
                   <div style={{fontFamily:'Montserrat'}} className={`flex flex-col gap-2 ${styles.dataBook}`}>
                        <img src={data?.img} alt="Capa do livro" width={150} className='m-auto'/>
                    <div style={{width:'100px'}}>
                    </div>
                        <div className='flex flex-col'>
                            <div>
                                <h1 className='flex flex-col gap-1'><strong>Título:</strong> <h1>{data.title}</h1></h1>
                                <h1 className='flex flex-col gap-1'><strong>Autor:</strong> <h1>{data.autor}</h1></h1>
                                <h1 className='flex flex-col gap-1'><strong>Editora:</strong> <h1>{data.editora}</h1></h1>
                                <hr />
                            </div>
                            <div style={{maxHeight:'300px'}} className='overflow-auto mt-1'>
                                <p>{data.summary}</p>
                            </div>
                        </div>
                   </div>
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose1} autoFocus>
                    Fechar
                </Button>
                </DialogActions>
            </Dialog>
    
        </div>
    )
}
