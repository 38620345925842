import { ToastContainer, toast } from "react-toastify";
import Menu from "../../components/menu";
import Footer from "../../components/footer";
import { authApi } from "../../services/api/api";
import useAuth from "../../hooks/useAuth";
import styles from './styles.module.css'

import { useQuery, keepPreviousData } from "@tanstack/react-query"
import { useRef, useState } from "react";

import IconButton from '@mui/material/IconButton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import User from "../../components/user";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Switch from '@mui/material/Switch';
import SkeletonUsers from "../../components/skeletons/user";

export default function Usuarios(){

    const api = authApi()

    const { user, theme }  = useAuth()

    const [page, setPage] = useState(1)

    const [newUser, setNewUser] = useState(false)

    const [search, setSearch] = useState('')

    const [file, setfile] = useState('')

    const [image, setImage] = useState('')

    const [name, setName] = useState('')

    const [email, setEmail] = useState('')

    const [password, setPassword] = useState('')


    const [checked, setChecked] = useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setChecked(event.target.checked);
    };


    const { data } = useQuery(
        {
            queryKey: ['usuarios', page, search,newUser, handleDelete], 
            queryFn: () => api.usuarios(search,page), 
            // placeholderData:keepPreviousData, 
            // staleTime: 5 * 60 * 1000
        }
    )

    function handleSetPage(){
        if(page < data?.last_page){
            setPage(page + 1)
        }
    }

    async function handleAddUser(){
        setOpen(true)
    }

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    let inputRef = useRef<HTMLInputElement>(null)

    function handleImg(){
        if(inputRef){
            inputRef.current?.click()
        }
    }

    function handleFile(e: React.ChangeEvent<HTMLInputElement>){
        const selectedFiles = e.target.files as FileList

        if(selectedFiles?.[0].size > 1048576){
            toast.error('A imagem não pode ultrapassar 1mb.',{theme:'dark',toastId: 'password'})
            return
        }

        setfile(URL.createObjectURL(selectedFiles?.[0]))

        if(e.target.files){

            const files = e.target.files[0]

            const reader = new FileReader()
            reader.readAsBinaryString(e.target.files[0])
            reader.onload = (ev:any) => {
                setImage(`data:${files.type};base64,${btoa(ev.target.result)}`)
            }
        }
    }

    async function handleSave(){

        if(name === '' || email === '' || password === ''){
            toast.error('Preencha todos os dados.',{theme:'dark',toastId: 'dados'})
            return
        }
        toast.loading('carregando.',{theme:'dark',toastId: 'loadingUser'})
        const data = {
            'name': name,
            'email': email,
            'password': password,
            'admin': checked,
            'img': image,

        }
        const response = await api.setUser(data)
 
        toast.dismiss('loadingUser')
        if(response === 200){
            handleClose()
            setNewUser(!newUser)
            toast.success('Usuário cadastrado com sucesso.',{theme:'dark',toastId: 'succesUser'})
        }else{
            toast.error('Ocorreu algum erro tente novamente mais tarde.',{theme:'dark',toastId: 'errorUser'})
        }
        
    }

    async function handleDelete(id:number){
        
        toast.loading('Preencha todos os dados.',{theme:'dark',toastId: 'deleteUser'})

        const response = await api.deleteUser(id)

        if(response === 200){
            setNewUser(!newUser)
            toast.dismiss('deleteUser')
            toast.success('Usuário deletado com sucesso.',{theme:'dark',toastId: 'succesDeleteUser'})
        }else{
            toast.dismiss('deleteUser')
            toast.error('Ocorreu algum erro tente novamente mais tarde.',{theme:'dark',toastId: 'errorUser'})
        }
    }

    return (
        <div className={`main w-screen bg-gray-100 ${theme}`}>
        <Menu/>
        <div className={`content w-screen min-h-screen flex justify-center dark:bg-slate-800 dark:text-white`}>
            <div className="w-4/5 flex justify-center p-8">
                <div className=" w-full">
                    <div className="flex justify-center flex-col">
                        <div className="flex justify-between items-center">
                            <h1>Usuários</h1>
                            <IconButton onClick={handleAddUser}>
                                <PersonAddIcon className="text-black dark:text-white"/>
                            </IconButton>
                        </div>
                            <hr />
                        <div className="my-2">
                            <input type="text" placeholder="Pesquisar por nome ou e-mail" className="rounded p-1 sm:w-80 w-full text-black" value={search} onChange={(e) => setSearch(e.target.value)}/>
                        </div>
                        <div className="dark:bg-gray-600 bg-white mt-4 p-4 gap-4">
                            {data ? (
                                data.data.map((item:any) => <User key={item.id} user={item} handleDelete={handleDelete} newUser={newUser} setNewUser={setNewUser}/>)
                            ) : <SkeletonUsers/>}
                        </div>
                        <div className="flex gap-4 items-center justify-center dark:bg-gray-600 bg-white">
                            <IconButton disabled={page === 1 ? true : false} onClick={() => setPage((old) => Math.max(old - 1, 1))}>
                                <ArrowBackIosNewIcon/>
                                <span className="text-lg">{window.screen.width < 500 ? '' : 'Página Anterior'}</span>
                            </IconButton>
                            <span className="text-gray-400">Página atual {page}</span>
                            <IconButton disabled={page === data?.last_page ? true : false} onClick={handleSetPage}>
                                <span className="text-lg">{window.screen.width < 500 ? '' : 'Próxima Página'}</span>
                                <ArrowForwardIosIcon/>
                            </IconButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Adicionar Usuário."}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                   <div style={{fontFamily:'Montserrat'}} className='flex gap-2 items-center flex-col sm:w-80'>
                        <div onClick={handleImg} style={{width:'100px', height:'100px'}} className="rounded-full bg-gray-300 flex justify-center items-center cursor-pointer">
                            <span className={`${image ? 'hidden' : ''}`}>Foto</span>
                            <img className={`${image ? '' : 'hidden'}`} src={image} alt="" style={{width:'100px', height:'100px', borderRadius:'50%'}}/>
                            <input name="image" type="file" accept="image/*" className="hidden" ref={inputRef} onChange={(e) => {handleFile(e)}}/>
                        </div> 
                        <div className="w-full">
                            <div className="flex flex-col">
                                <label htmlFor="">Nome:</label>
                                <input value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder="Nome Completo" className="bg-gray-200 rounded p-1"/>
                            </div>
                            <div className="flex flex-col my-2">
                                <label htmlFor="">E-mail:</label>
                                <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="E-mail" className="bg-gray-200 rounded p-1"/>
                            </div>
                            <div className="flex flex-col">
                                <label htmlFor="">Senha:</label>
                                <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Senha" className="bg-gray-200 rounded p-1"/>
                            </div>
                            <div className="flex items-center">
                                <label htmlFor="">Administrador:</label>
                                <Switch
                                checked={checked}
                                onChange={handleChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </div>
                        </div>
                   </div>
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleSave} autoFocus>
                    Salvar
                </Button>
                </DialogActions>
            </Dialog>

        <ToastContainer/>
        {/* <Footer/> */}
        <div className="w-full h-8 dark:bg-slate-800"></div>
    </div>
    )
}