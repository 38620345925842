import { useEffect, useState } from "react"
import Footer from "../../components/footer"
import Menu from "../../components/menu"
import useAuth from "../../hooks/useAuth"
import { authApi } from "../../services/api/api"
import Book from "../../components/book"
import styles from './styles.module.css'

import ScrollToTop from "../../components/scrollToTop"

import { useInfiniteQuery, keepPreviousData } from '@tanstack/react-query'

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import { useLocation } from "react-router-dom"
import SkeletonBiblioteca from "../../components/skeletons/biblioteca"
import MenuBookIcon from '@mui/icons-material/MenuBook';

import { useInView } from 'react-intersection-observer';


import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


export default function Biblioteca(){

    const { ref, inView } = useInView();

    const resquestRedirect = useLocation()

    const [atualizar, setAtualizar] = useState(false)

    if(resquestRedirect.state === 'atualizar'){
        setAtualizar(true)
    }

    const api = authApi()

    const [search, setSearch] = useState(resquestRedirect.state ?? '')

    const [order, setOrder] = useState('')

    const [gen, setGen] = useState('')

    const [letra, setLetra] = useState('')

    const [lastPage, setlastPage] = useState(false)

    const [likes, setLikes] = useState('')

    const { user, theme }  = useAuth()

    const [open, setOpen] = useState(false);

    const handleClickOpen = async() => {
        setOpen(true);
    };
    
    const handleClose = () => {
      setOpen(false);
    };

    const [open1, setOpen1] = useState(false);

    const handleClickOpen1 = async() => {
        setOpen1(true);
    };
    
    const handleClose1 = () => {
      setOpen1(false);
    };

    const getData = async({pageParam}) => {

        setlastPage(pageParam.last_pages)
        
        const data = await api.list(pageParam.nextPage, search, order, gen, letra, likes)

        return data 
        
    }

    
    const {data, fetchNextPage, hasNextPage, isFetchingNextPage} = useInfiniteQuery({
        queryKey: ['books',  search, order, gen, atualizar, letra, likes],
        queryFn: getData,
        // placeholderData:keepPreviousData, 
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPages) => { 

            const nextPage = allPages.length + 1 

            let last_pages = false

            if(nextPage >= lastPage.last_page){
                last_pages = true
            }else{
                last_pages = false
            }

            return {nextPage, last_pages}

        }
    })


    function handleOrder(e:any){
        if(e.target.value !== ''){
            setOrder(e.target.value)
        }
    }

    useEffect(() => {
        if(inView && !lastPage){
            fetchNextPage()
        }
    },[inView, lastPage, fetchNextPage,isFetchingNextPage])


    async function handleChatGpt(){
        // const data = await api.bookIndicationGPT({teste:'teste'})
        // console.log(data)
    }


    return(
        <div className={`w-screen h-screen bg-gray-100 ${theme}`}>
            <Menu/>
            <div className={`w-screen flex flex-col items-center justify-start dark:bg-slate-800 p-2 min-h-screen bg-gray-100`}>
                <div className={`w-full flex justify-center px-36 ${styles.hiddenFilter}`}>
                    <div className="w-full bg-gray-300 dark:bg-slate-900 p-2  m-4 rounded flex gap-3 justify-between">
                        <div className="flex flex-col flex-1">
                            <label className="text-gray-600 dark:text-white my-2">Pesquisar por Autor ou Título:</label>
                            <input type="text" placeholder="Pesquisar por Autor ou Título" className="h-8 rounded w-full" value={search} onChange={(e) => setSearch(e.target.value)}/>
                        </div>
                        <div className="flex flex-col">
                            <label className="text-gray-600 dark:text-white my-2">Gênero:</label>
                            <select className="h-8 rounded" onChange={(e) => setGen(e.target.value)}>
                                <option value="todos"></option>
                                <option value="Ação e aventura">Ação e aventura</option>
                                <option value="Auto Ajuda">Auto Ajuda</option>
                                <option value="Arte e Fotografia">Arte e Fotografia</option>
                                <option value="Auto Biografia">Auto Biografia</option>
                                <option value="Biografia">Biografia</option>
                                <option value="Conto">Conto</option>
                                <option value="Fantasia">Fantasia</option>
                                <option value="Fábula">Fábula</option>
                                <option value="Ficção Científica">Ficção Científica</option>
                                <option value="Horror">Horror</option>
                                <option value="Romance">Romance</option>
                                <option value="Infantil">Infantil</option>
                                <option value="História">História</option>
                                <option value="Humor">Humor</option>
                                <option value="Religião e Espiritualidade">Religião e Espiritualidade</option>
                                <option value="Ciências Sociais">Ciências Sociais</option>
                                <option value="Tecnologia e Ciência">Tecnologia e Ciência</option>
                                <option value="Negócios">Negócios</option>
                            </select>
                        </div>
                        <div className="flex flex-col">
                            <label className="text-gray-600 dark:text-white my-2">Ordem Alfabética:</label>
                            <select className="h-8 rounded" value={order} onChange={(e) => setOrder(e.target.value)}>
                                <option value=""></option> 
                                <option value="ASC">Crescente</option>
                                <option value="DESC">Decrescente</option>
                            </select>
                        </div>
                        <div className="flex flex-col">
                            <label className="text-gray-600 dark:text-white my-2">Letra:</label>
                            <select className="h-8 rounded" value={letra} onChange={(e) => setLetra(e.target.value)}>
                                <option value=""></option> 
                                <option value="A">A</option>
                                <option value="B">B</option>
                                <option value="C">C</option>
                                <option value="D">D</option>
                                <option value="E">E</option>
                                <option value="F">F</option>
                                <option value="G">G</option>
                                <option value="H">H</option>
                                <option value="I">I</option>
                                <option value="J">J</option>
                                <option value="K">K</option>
                                <option value="L">L</option>
                                <option value="M">M</option>
                                <option value="N">N</option>
                                <option value="O">O</option>
                                <option value="P">P</option>
                                <option value="Q">Q</option>
                                <option value="R">R</option>
                                <option value="S">S</option>
                                <option value="T">T</option>
                                <option value="U">U</option>
                                <option value="V">V</option>
                                <option value="W">W</option>
                                <option value="X">X</option>
                                <option value="Y">Y</option>
                                <option value="Z">Z</option>
                            </select>
                        </div>
                        <div className="flex flex-col">
                            <label className="text-gray-600 dark:text-white my-2">Curtidas:</label>
                            <select className="h-8 rounded" value={likes} onChange={(e) => setLikes(e.target.value)}>
                                <option value=""></option> 
                                <option value="ASC">Menor</option> 
                                <option value="DESC">Maior</option> 
                            </select>
                        </div>
                    </div>
                </div>

                <button onClick={handleClickOpen1} className={`${styles.hiddenBtn} p-1 bg-blue-900 text-white rounded-lg px-4 m-2`}>Filtro</button>

                <div className="w-full flex gap-4 px-8 justify-start items-center flex-col">
                    <div className="flex flex-wrap justify-center gap-3 px-8">
                        {data?.pages ? (data?.pages.map((item:any) => item.data.map((item) => (<Book key={item?.id} data={item}/>)))) : <SkeletonBiblioteca/>}
                        {isFetchingNextPage ? <SkeletonBiblioteca/> : ''}
                    </div>
                    <div className="flex justify-center mt-8">
                        <button ref={ref} onClick={() => fetchNextPage()} disabled={lastPage}></button>
                    </div>
                </div>

            </div>
            <ToastContainer/>
            <div className="w-full h-8 dark:bg-slate-800"></div>
            {/* <Footer/> */}
            {/* <button onClick={handleClickOpen} className="text-blue-700 dark:text-white fixed bottom-5 right-5 border-2 rounded p-1 flex justify-center items-center border-blue-700 dark:border-white" title="Me indique um livro">
                <MenuBookIcon  fontSize="large"/>
            </button> */}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Me indique um livro!"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div className="flex flex-col">
                    <Button autoFocus onClick={handleChatGpt}>
                        Com base nas minhas escolhas
                    </Button>
                    <div className="w-full">
                        <textarea className="w-full border rounded"></textarea>
                        </div>
                    </div>
                </DialogContentText>    
                </DialogContent>
                <DialogActions>
                <Button autoFocus>
                    Buscar
                </Button>
                <Button onClick={handleClose} color="error" autoFocus>
                    Cancelar
                </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={open1}
                onClose={handleClose1}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Filtro"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div className="flex flex-col">
                        <div className="w-full bg-gray-300 dark:bg-slate-900 p-3 rounded flex flex-col gap-3 justify-between">
                            <div className="flex flex-col flex-1">
                                <label className="text-gray-600 dark:text-white my-2">Pesquisar por Autor ou Título:</label>
                                <input type="text" placeholder="Pesquisar por Autor ou Título" className="h-8 rounded w-full" value={search} onChange={(e) => setSearch(e.target.value)}/>
                            </div>
                            <div className="flex flex-col">
                                <label className="text-gray-600 dark:text-white my-2">Gênero:</label>
                                <select className="h-8 rounded" onChange={(e) => setGen(e.target.value)}>
                                    <option value="todos"></option>
                                    <option value="Ação e aventura">Ação e aventura</option>
                                    <option value="Auto Ajuda">Auto Ajuda</option>
                                    <option value="Arte e Fotografia">Arte e Fotografia</option>
                                    <option value="Auto Biografia">Auto Biografia</option>
                                    <option value="Biografia">Biografia</option>
                                    <option value="Conto">Conto</option>
                                    <option value="Fantasia">Fantasia</option>
                                    <option value="Fábula">Fábula</option>
                                    <option value="Ficção Científica">Ficção Científica</option>
                                    <option value="Horror">Horror</option>
                                    <option value="Romance">Romance</option>
                                    <option value="Infantil">Infantil</option>
                                    <option value="História">História</option>
                                    <option value="Humor">Humor</option>
                                    <option value="Religião e Espiritualidade">Religião e Espiritualidade</option>
                                    <option value="Ciências Sociais">Ciências Sociais</option>
                                    <option value="Tecnologia e Ciência">Tecnologia e Ciência</option>
                                    <option value="Negócios">Negócios</option>
                                </select>
                            </div>
                            <div className="flex flex-col">
                                <label className="text-gray-600 dark:text-white my-2">Ordem Alfabética:</label>
                                <select className="h-8 rounded" value={order} onChange={(e) => setOrder(e.target.value)}>
                                    <option value=""></option> 
                                    <option value="ASC">Crescente</option>
                                    <option value="DESC">Decrescente</option>
                                </select>
                            </div>
                            <div className="flex flex-col">
                                <label className="text-gray-600 dark:text-white my-2">Letra:</label>
                                <select className="h-8 rounded" value={letra} onChange={(e) => setLetra(e.target.value)}>
                                    <option value=""></option> 
                                    <option value="A">A</option>
                                    <option value="B">B</option>
                                    <option value="C">C</option>
                                    <option value="D">D</option>
                                    <option value="E">E</option>
                                    <option value="F">F</option>
                                    <option value="G">G</option>
                                    <option value="H">H</option>
                                    <option value="I">I</option>
                                    <option value="J">J</option>
                                    <option value="K">K</option>
                                    <option value="L">L</option>
                                    <option value="M">M</option>
                                    <option value="N">N</option>
                                    <option value="O">O</option>
                                    <option value="P">P</option>
                                    <option value="Q">Q</option>
                                    <option value="R">R</option>
                                    <option value="S">S</option>
                                    <option value="T">T</option>
                                    <option value="U">U</option>
                                    <option value="V">V</option>
                                    <option value="W">W</option>
                                    <option value="X">X</option>
                                    <option value="Y">Y</option>
                                    <option value="Z">Z</option>
                                </select>
                            </div>
                            <div className="flex flex-col">
                                <label className="text-gray-600 dark:text-white my-2">Curtidas:</label>
                                <select className="h-8 rounded" value={likes} onChange={(e) => setLikes(e.target.value)}>
                                    <option value=""></option> 
                                    <option value="ASC">Menor</option> 
                                    <option value="DESC">Maior</option> 
                                </select>
                            </div>
                        </div>
                    </div>
                </DialogContentText>    
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose1} color="error" autoFocus>
                    Fechar
                </Button>
                </DialogActions>
            </Dialog>
            <ScrollToTop/>
        </div>
    )
}