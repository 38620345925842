import moment from "moment"

export default function MiniBook({item, handleClickOpen, openPost, handleDismiss}:any){

    const start = moment(item?.start).format('DD/MM/YYYY')

    const end = moment(item?.finaly).format('DD/MM/YYYY')

    return (
        
        <div className="relative w-full flex flex-col dark:bg-gray-600 rounded bg-white" style={{maxWidth:'350px', height:'210px'}}>
            <div className={`text-center text-white ${item?.status == 'Reservado' ? 'bg-blue-600' : 'bg-green-600'}`} style={{borderRadius: '5px 5px 0px 0px'}}>
                <span>{item?.status}</span>
            </div>
            <div className="flex p-2 gap-2">
                <div className="">
                    <img src={item?.books.img} alt="imagem do livro" width={110} height={170} />
                </div>
                <div className="p-1 flex flex-col justify-between">
                    <div>
                        <div className="overflow-auto max-h-20">
                            <h1 className="font-semibold w-full" style={{textTransform:'capitalize'}}>{item?.books.title}</h1>
                            <p style={{textTransform:'capitalize'}}>{item?.books.autor}</p>
                        </div>
                        <div className="mt-2">
                            <small className="flex">Retirado: {start}</small>
                            <small className="flex">Entregue: {end}</small>
                        </div>
                    </div>
                    <div className="flex justify-between">
                    {item?.status != 'Entregue' && openPost ? (<button onClick={() => handleClickOpen(item?.books.id)} className="bg-blue-900 rounded text-white hover:bg-blue-600 px-1"><small>Publicar</small></button>) : ''}
                    {item?.status != 'Entregue' ? <button onClick={() => handleDismiss(item?.id)} className="dark:bg-blue-900 dark:hover:bg-blue-700 bg-blue-900 hover:bg-blue-600 text-white rounded px-1"><small>Devolver</small></button> : ''}
                    </div>
                </div>
            </div>
        </div>
        
    )
}