
import useAuth from "../../hooks/useAuth";

import { Navigate } from 'react-router-dom'


export default function IsAuth({children}: any){

    const auth = useAuth()
  
    if(!auth.user){
        return (
            // navigation(location.pathname)
            <Navigate to='/'/>
        )
    }else{
        // navigation(location.pathname)
        return children
    }
}