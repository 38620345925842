import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useState } from 'react';
import { EditorState, convertToRaw, ContentState, convertFromHTML  } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';



export default function TextEditor({text,setText}:any){

  // const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
  
  const contentBlock = htmlToDraft(text ?? '')
  const contentState = ContentState.createFromBlockArray(contentBlock)
  const editorContent = EditorState.createWithContent(contentState)
  
  const [editorState, setEditorState] = useState(() => editorContent)


  setText(draftToHtml(convertToRaw(editorState.getCurrentContent())))

    return (
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={setEditorState}
          defaultContentState={text}
          editorStyle={{height:'250px', backgroundColor:'white'}} 
        />
    );
  
}