import axios from "axios";
import useAuth from "../../hooks/useAuth";


const api = axios.create({
    baseURL: process.env.REACT_APP_URL
})
// https://api.acreditarbanco.com.br/api/v1/login


// const api = axios.create({
//     baseURL: 'https://api.acreditarbanco.com.br/api/v1'
// })

// const api = axios.create({
//     baseURL: 'http://127.0.0.1:8000/api/v1'
// })

// const api = axios.create({
//     baseURL: 'http://192.168.15.20:8000/api/v1'
// })

api.defaults.withCredentials = true
api.defaults.withXSRFToken = true
api.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('biblioteca-acreditar-token')}`;


export const authApi = () => ({
    
    logged: async(token:string) => {

        api.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('biblioteca-acreditar-token')}`;

        try{
            const response = await api.post('/user',{
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json'
                }
            })
            
            return response.data
        }catch(e){
            return false
        }
    },
    signin: async(email:string, password: string) => {

        try{
            const response = await api.post('/login',{
                email:email,
                password:password
            })

            api.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
            
            return response.data
        }catch(e){
            return e
        }
    },
    storeBook: async(data:object) =>{
        try{

            const response = await api.post('/book-store',{
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                },
                body: data
            })

            return response
             
        }catch(e){
            console.log(e)
        }
    },
    getBooks: async(page:number) => {
        try{
            const response = await api.post(`/get-books?page=${page}`,{
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                }
            })

            return response.data

        }catch(e){
            console.log(e)
        }
    },
    updateBook: async(data:any) => {
        try{
            const response = await api.post(`/update-book`,{
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                },
                data:data
            })

            return response.data

        }catch(e){
            console.log(e)
        }
    },
    deleteBook: async(id:number) => {
        try{
            const response = await api.get(`/delete-book/${id}`,{
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                }
            })

            return response.data

        }catch(e){
            console.log(e)
        }
    },
    list: async(page:number, search?:string, order?:string, gen?:string, letra?:string, likes?:string) => {
        try{

            const response = await api.post(`/books?page=${page}`,{
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                },
                data: {
                    search: search,
                    order: order,
                    gen:gen, 
                    letra:letra,
                    likes:likes
                }
            })

            return response.data

        }catch(e){
            console.log(e)
        }
    },
    selectBook: async(book_id:number, user_id:number) => {
        try{
            const response = await api.post('/select-book',{
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                },
                data: {
                    user_id:user_id,
                    book_id:book_id
                }
            })

            return response.data
        }catch(e){
            console.log(e)
        }
    },
    myBooks: async(user_id?:number) => {

        if(!user_id) return
        try{

            const response = await api.get(`/my-books/${user_id}`,{
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                }
            })

            return response.data

        }catch(e){
            console.log(e)
        }
    },
    likeBook: async(book_id:number, user_id:number) => {
        try{

            const response = await api.get(`/like-book/${book_id}/${user_id}`,{
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                }
            })

            return response.data

        }catch(e){
            console.log(e)
        }
    },
    likeIndication: async(indication_id:number, user_id:number) =>{
        try{

            const response = await api.get(`/like-indication/${indication_id}/${user_id}`,{
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                }
            })

            return response.data

        }catch(e){
            console.log(e)
        }
    },
    setIndication: async(data:any) => {
        try{

            const response = await api.post('/indication-store',{
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                },
                data: data
            })

            return response.data
        }catch(e){
            console.log(e)
        }
    },
    updateIndication: async(data:any) => {
        try{

            const response = await api.post('/indication-edit',{
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                },
                data: data
            })

            return response.data
        }catch(e){
            console.log(e)
        }
    },
    destroyIndication: async(id:number) => {
        try{

            const response = await api.delete(`/indication-destroy/${id}`,{
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                }
            })

            return response.data
        }catch(e){
            console.log(e)
        }
    },
    getIndications: async(page:number, items?:number, data?:any) => {
        try{

            const response = await api.post(`/indications/${items}?page=${page}`,{
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                },
                data
            })

            return response.data
        }catch(e){
            console.log(e)
        }
    },
    setComment: async(data:any) => {
        try{

            const response = await api.post('/set-comment',{
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                },
                data: data
            })

            return response.data
        }catch(e){
            console.log(e)
        }
    },
    likeCommet: async(user_id:number, comment_id:number) => {
        try{

            const response = await api.post('/like-comment',{
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                },
                data: {
                    user:user_id,
                    comment:comment_id
                }
            })

            return response.data
        }catch(e){
            console.log(e)
        }
    },
    updateComment: async(data:any) => {
        try{

            const response = await api.post('/edit-comment',{
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                },
                data: data
            })

            return response.data
        }catch(e){
            console.log(e)
        }
    },
    deleteComment: async(id:number) => {
        try{

            const response = await api.delete(`/delete-comment/${id}`,{
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                }
            })

            return response.data
        }catch(e){
            console.log(e)
        }
    },
    setPost: async(data:any) => {
        try{

            const response = await api.post('/store-post',{
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                },
                data: data
            })

            return response.data
        }catch(e){
            console.log(e)
        }
    },
    setNewPost: async(data:any) => {
        try{

            const response = await api.post('/store-new-post',{
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                },
                data: data
            })

            return response.data
        }catch(e){
            console.log(e)
        }
    },
    updatePost: async(data:any) => {
        try{

            const response = await api.post('/edit-post',{
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                },
                data: data
            })

            return response.data
        }catch(e){
            console.log(e)
        }
    },
    destroyPost: async(id:number) => {
        try{

            const response = await api.delete(`/delete-post/${id}`,{
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                }
            })

            return response.data
        }catch(e){
            console.log(e)
        }
    },
    getPosts: async(page:number, data?:any) => {

        try{

            const response = await api.post(`/posts?page=${page}`,{
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                },
                data
            })

            return response.data
        }catch(e){
            console.log(e)
        }
    },
    setCommentPost: async(data:any) => {
        try{

            const response = await api.post('/comment-post',{
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                },
                data: data
            })

            return response.data
        }catch(e){
            console.log(e)
        }
    },
    likePost: async(user_id:number, post_id:number) => {
        try{

            const response = await api.post('/like-post',{
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                },
                data: {
                    user:user_id,
                    post:post_id
                }
            })

            return response.data
        }catch(e){
            console.log(e)
        }
    },
    usuarios: async(search?:string, page?:number) => {
        try{

            const response = await api.post(`/usuarios?page=${page}`,{
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                },
                data: search
            })

            return response.data
        }catch(e){
            console.log(e)
        }
    },
    selectUser: async() => {
        try{

            const response = await api.get(`/users`,{
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                }
            })

            return response.data
        }catch(e){
            console.log(e)
        }
    },
    setUser: async(data:any) => {
        try{

            const response = await api.post(`/novo-usuario`,{
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                },
                data: data
            })

            return response.data
        }catch(e){
            console.log(e)
        }
    },
    resetPassword: async(email:string) => {
        try{

            const response = await api.post(`/forgot-password`,{
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                },
                email: email
            })

            return response.data
        }catch(e){
            console.log(e)
        }
    },
    updateUser: async(data:any) => {
        try{

            const response = await api.post(`/update-usuario`,{
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                },
                data: data
            })

            return response.data
        }catch(e){
            console.log(e)
        }
    },
    updatePerfil: async(data:any) => {
        try{

            const response = await api.post(`/edit-perfil`,{
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                },
                data:data
            })

            return response.data
        }catch(e){
            console.log(e)
        }
    },
    getBooksUser: async(data:any, page:number) => {
        try{

            const response = await api.post(`/books-usuario?page=${page}`,{
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                },
                data: data
            })

            return response.data
        }catch(e){
            console.log(e)
        }
    },
    dismissBooksUser: async(id:number) => {
        try{

            const response = await api.get(`/book-dismiss/${id}`,{
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                }
            })

            return response.data
        }catch(e){
            console.log(e)
        }
    },
    deleteUser: async(id:number) => {
        try{

            const response = await api.post(`/delete-user`,{
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                },
                data: id
            })

            return response.data
        }catch(e){
            console.log(e)
        }
    },
    liberarPosts: async(data:any) => {
        try{
            const response = await api.post(`/liberar-posts`,{
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                },
                data: data
            })

            return response.data
        }catch(e){
            console.log(e)
        }
    },
    bookIndicationGPT: async(data:any) => {
        try{
            const response = await api.post(`/book-chatgpt`,{
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                },
                data: data
            })

            return response.data
        }catch(e){
            console.log(e)
        }
    }
})

