import Menu from "../../components/menu";
import useAuth from "../../hooks/useAuth";
import Switch from '@mui/material/Switch';
import styles from './styles.module.css'

import { keepPreviousData, useInfiniteQuery } from "@tanstack/react-query"
import { authApi } from "../../services/api/api";
import { useEffect, useState } from "react";

import MiniBookUser from "../../components/miniBookUserAdmin";
import SkeletonMiniBook from "../../components/skeletons/miniBook";
import Footer from "../../components/footer";
import { ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";

export default function Usuario(){

    const api = authApi()

    const {state} = useLocation()

    const {user, theme} = useAuth()

    const [atualizar, setAtualizar] = useState(false)
    
    const [alugados, setAlugados] = useState(true)

    const [finalizados, setFinalizados] = useState(false)

    const [lastPage, setlastPage] = useState(false)

    const getData = async({pageParam}) => {

        setlastPage(pageParam.last_pages)

        const dados = {
            'user' : state.id,
            'alugados' : alugados,
            'finalizados' : finalizados
        }
        
        const data = await api.getBooksUser(dados, pageParam.nextPage)
   
        return data 
    }

    const {data, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching} = useInfiniteQuery({
        queryKey: ['books',atualizar, alugados, finalizados],
        queryFn: getData,
        // placeholderData:keepPreviousData, 
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPages) => { 

            const nextPage = allPages.length + 1 

            let last_pages = false

            if(nextPage >= lastPage.last_page){
                last_pages = true
            }else{
                last_pages = false
            }

            return {nextPage, last_pages}

        }
    })

    return(
        <div className={`main w-screen bg-gray-100 ${theme}`} style={{'fontFamily': 'Montserrat'}}>
        <Menu/>
            <div className={` w-screen min-h-screen flex justify-cente dark:bg-slate-800 bg-gray-100 dark:text-white`}>
                <div className="w-4/5 flex justify-center m-auto min-h-screen mt-8">
                    <div className=" w-full flex justify-center">
                        <div className="w-full">
                            <div className="flex flex-col ">

                                <div className="w-full flex flex-col items-center ">
                                    <h1 className=" pb-4" style={{lineHeight:0}}>Livros alugados</h1>
                                    <div className="flex">
                                        <div className="flex items-center">
                                            <label>Finalizados</label>
                                            <Switch color="success" checked={finalizados} onChange={() => setFinalizados(!finalizados)}/>
                                        </div>
                                        <div className="flex items-center">
                                            <label>Alugados</label>
                                            <Switch color="success" checked={alugados} onChange={() => setAlugados(!alugados)}/>
                                        </div>
                                    </div>
                                </div>
                                <hr />

                                <div className="flex justify-center flex-wrap gap-4 mt-4">
                                    {data ? data.pages.map((books) => books.data.map((book) => <MiniBookUser atualizar={atualizar} setAtualizar={setAtualizar} key={book.id} item={book}/>)) : <SkeletonMiniBook/>}
                                    {isFetching && <SkeletonMiniBook/>}
                                </div>

                                <div className="flex justify-end my-4">
                                    <button onClick={() => fetchNextPage()} className="bg-gray-200 hover:bg-gray-400 rounded py-2 px-3 dark:bg-slate-700 dark:hover:bg-slate-600 dark:text-black">Carregar mais</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer/>
            {/* <Footer/> */}

        </div>
    )
}