import PublishIcon from '@mui/icons-material/Publish';

export default function ScrollToTop(){

    function scrolltop(){
        window.scrollTo(0, 0);
    }

    return(
        <button onClick={scrolltop} className='fixed bottom-5 left-5 border-2 border-blue-700 text-blue-700 rounded z-20 bg-white'>
            <PublishIcon/>
        </button>
    )
} 