import { Link, redirect, useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { Search, House, Library, Book, ElipseSpread, Star, Moon, Sun, Profile } from "../../assets/icons/icons";

import styles from './style.module.css'
import { useEffect, useState } from "react";
import MenuAdmin from "../menuAdmin";

import LogoutIcon from '@mui/icons-material/Logout';
import { IconButton } from "@mui/material";
import MenuBookIcon from '@mui/icons-material/MenuBook';


export default function Menu(){

    const { user, theme, toggleTheme, signout } = useAuth()

    const { pathname } = useLocation()

    const [ leftMenu, setLeftMenu] = useState(-230)

    const [ hidden, setHidden] = useState('')

    const [text, setText] = useState('')

    useEffect(()=>{
        if(window.screen.width < 500){
            setHidden('hidden')
        }
    },[])

    const navigate = useNavigate()

    function handleSignOut(){
        signout()
        navigate('/')
    }

    function handleSearch(){

        navigate('/biblioteca', {state:text})
    }

    function handleMenuLeft(){

        if(window.screen.width < 500){
            setHidden((old) => old == 'hidden' ? '' : 'hidden')
        }
        if(leftMenu < 0){
            setLeftMenu(0)
        }else{
            setLeftMenu(-230)
        }
    }

    return (
        <div className={`${theme} z-50 overflow-hidden w-screen ${styles.maxView} `}>
            <div className="overflow-hidden w-screen flex justify-center bg-white shadow dark:bg-slate-900 dark:text-white" style={{fontFamily:'montserrat'}}>
                <header className="overflow-hidden w-4/5">
                    <nav className="flex justify-between items-center p-4 overflow-hidden">
                        <Link to="/dashboard">
                            {theme === 'dark' ? (<img src="../../assets/images/logoBranco.svg" alt="Logo" className="w-32"/>) : 
                            (<img src="../../assets/images/logoAzul.svg" alt="Logo" className="w-32"/>)}
                            
                        </Link>
                        <menu className="gap-6 hidden lg:flex">
                            <Link to='/dashboard' className={`hover:text-sky-600 ${styles.link} ${pathname === '/dashboard' ? styles.active : ''}`}>Home</Link>
                            {/* <Link to='/perfil' className={`hover:text-sky-600 ${styles.link} ${pathname === '/perfil' ? styles.active : ''}`}>Perfil</Link> */}
                            <Link to='/biblioteca' className={`hover:text-sky-600 ${styles.link} ${pathname === '/biblioteca' ? styles.active : ''}`}>Biblioteca</Link>
                            <Link to='/meus-livros' className={`hover:text-sky-600 ${styles.link} ${pathname === '/meus-livros' ? styles.active : ''}`}>Novidades</Link>
                            <Link to='/indicacoes' className={`hover:text-sky-600 ${styles.link} ${pathname === '/indicacoes' ? styles.active : ''}`}>Indicações</Link>
                            <Link to='/forum' className={`hover:text-sky-600 ${styles.link} ${pathname === '/forum' ? styles.active : ''}`}>Fórum</Link>
                        </menu>
                        <div className="flex items-center gap-6">
                            <div className="items-center justify-end hidden md:flex">
                                <input value={text} onChange={(e) => setText(e.target.value)} type="text" placeholder="Pesquisar Livro" className="rounded p-1 border-none bg-gray-200 text-gray-900"/>
                                <button className="absolute text-gray-400" onClick={handleSearch}>
                                    {Search}
                                </button>
                            </div>
                            <button onClick={handleMenuLeft}>
                                <img src={`${user?.img}`} alt="Foto do usuário" className="w-12 h-12 rounded-full"/>
                            </button>
                            <div>
                                <button onClick={() => toggleTheme( theme === 'white' ? 'dark' : 'white')}>
                                    { theme === 'white' ? <div className="bg-gray-900 rounded-full text-white">{Moon}</div> : <div className="bg-yellow-500 rounded-full">{Sun}</div>}
                                </button>
                            </div>
                        </div>
                    </nav>
                </header>
            </div>

            <div className={`absolute mt-2 mr-4 r-0 ${styles.animateMenu} z-50 ${hidden}`} style={{right:leftMenu}}>
                <menu className={`flex flex-col w-50 bg-gray-200 rounded-md dark:bg-slate-900 dark:text-white`}>
                    <div className="w-full">
                        <Link to='/dashboard' className={`hover:text-sky-600 hover:bg-sky-300 hover:rounded-t-md flex items-end p-5 w-full ${pathname === '/dashboard' ? styles.activeLeft : ''}`}>
                            <div className="flex items-end">{House} <span className="h-5 ml-4">Home</span></div>
                        </Link>
                        <Link to='/perfil' className={`hover:text-sky-600 hover:bg-sky-300 hover:rounded-t-md flex items-end p-5 w-full ${pathname === '/perfil' ? styles.activeLeft : ''}`}>
                            <div className="flex items-end">{Profile} <span className="h-5 ml-4">Perfil</span></div>
                        </Link>
                        <Link to='/biblioteca' className={`hover:text-sky-600 hover:bg-sky-300 flex items-end p-5 ${pathname === '/biblioteca' ? styles.activeLeft : ''}`}>
                            {Library} <span className="h-5 ml-4">Biblioteca</span>
                        </Link>
                        <Link to='/meus-livros' className={`hover:text-sky-600 hover:bg-sky-300 flex items-end p-5 ${pathname === '/meus-livros' ? styles.activeLeft : ''}`}>
                            {Book} <span className="h-5 ml-4">Novidades</span>
                        </Link>
                        <Link to='/indicacoes' className={`hover:text-sky-600 hover:bg-sky-300 flex items-end p-5 ${pathname === '/indicacoes' ? styles.activeLeft : ''}`}>
                            {Star} <span className="h-5 ml-4">Indicações</span>
                        </Link>
                        <Link to='/forum' className={`hover:text-sky-600 hover:bg-sky-300 hover:rounded-b-md flex items-end p-5 ${pathname === '/forum' ? styles.activeLeft : ''}`}>
                            {ElipseSpread} <span className="h-5 ml-4">Fórum</span>
                        </Link>
                        { user?.admin === 1 ? (<MenuAdmin/>) : ''}
                        <button onClick={handleSignOut} className={`hover:text-sky-600 hover:bg-sky-300 hover:rounded-b-md flex items-end p-5 w-full`}>
                         <LogoutIcon/>
                         <span className="h-5 ml-4">Sair</span>
                        </button>
                    </div>
                </menu>
            </div>
        </div>
    )
}