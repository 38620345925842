import { useEffect, useState } from 'react'
import { userInterface } from "../../interfaces/userInterface"
import { AuthContext } from "./AuthContext"
import { authApi } from '../../services/api/api'
import { useLocation } from 'react-router-dom'


export const AuthProvider = ({children}: {children: JSX.Element}) => {

    const [user, setUser] = useState<userInterface | null>(null)

    const [theme, setTheme] = useState('white')

    const [token, setToken] = useState('')

    const [lastRoute, setLastRoute] = useState('')

    const api = authApi()

    const location = useLocation()


    useEffect(()=>{
        const themeStorage = localStorage.getItem('biblioteca-acreditar-theme')

        setLastRoute(location.pathname)
        console.log(lastRoute)
        if(themeStorage){
            setTheme(themeStorage)
        }
    },[])

    useEffect(()=>{
        const logged = async() =>{
            const storageData = localStorage.getItem('biblioteca-acreditar-token')

            if(storageData){
                setToken(storageData)
                const data = await api.logged(storageData)
                if(data){
                    setUser(data)
                }
            }
        }
        logged()
    },[])

    const toggleTheme = (theme:string) => {
        localStorage.setItem('biblioteca-acreditar-theme', theme)
        setTheme(theme)
    }

    const logged = async() => {
        const storageData = localStorage.getItem('biblioteca-acreditar-token')

        if(storageData){
            const data = await api.logged(storageData)
            if(data){
                setUser(data)
                return true
            }
            return false
        }
        return false
    }

    const signin = async(email:string, password:string) => {

        const data = await api.signin(email, password)

        if(data.response?.status > 400){
            return false
        }

        if(data && data.token){
            setUser(data)
            localStorage.setItem('biblioteca-acreditar-token', data.token)

            
            return true
        }

        return false
    }

    const signout = () => {

        localStorage.clear();
        
    }

    const storeBook = async(form:object) => {

        const response = await api.storeBook(form)

        return response?.data
    }

    return(
        <AuthContext.Provider value={{user, setUser, signin, signout, logged, theme, toggleTheme, storeBook, lastRoute, setLastRoute}}>
            {children}
        </AuthContext.Provider>
    )    

}