
import useAuth from "../../hooks/useAuth";

import { Navigate } from 'react-router-dom'


export default function IsAdmin({children}: any){

    const auth = useAuth()
    
    if(auth.user && auth.user.admin == 1){
        // navigation(location.pathname)
        return children
    }else if(auth.user){
        return (
            // navigation(location.pathname)
            <Navigate to='/dashboard'/>
        )
    }else{
        <Navigate to='/'/>
    }
}