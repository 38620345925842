import Skeleton from '@mui/material/Skeleton';

export default function SkeletonIndications(){

    const arrayItems = [1,2,3,4]

    return (
        <div className='flex gap-4 flex-wrap justify-center'>
            {arrayItems.map((item) => 
                <div key={item} style={{width:'350px', height:'170px'}} className='bg-gray-200 rounded flex p-2 gap-2'>

                    <div className='w-full'>
                        <div className='flex justify-start gap-2'>
                            <Skeleton variant="circular" width={50} height={50} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                            <Skeleton variant="text" width={140} height={50} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                        </div>
                        <div>
                            <Skeleton variant="text" width={200} height={50} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                        </div>
                        <div>
                            <Skeleton variant="text" width={200} height={50} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                        </div>
                    </div>
                    <div className=''>
                        <Skeleton variant="rounded" width={110} height={150} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                    </div>
                </div>
            )}
        </div>
    )
}