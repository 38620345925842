import { useEffect, useRef, useState } from 'react'
import useAuth from '../../hooks/useAuth'
import { useNavigate } from 'react-router-dom'

import styles from './login.module.css'

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { authApi } from '../../services/api/api';

export default function Login(){

    const navigate = useNavigate()

    const api = authApi()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const ref = useRef(null)

    const auth = useAuth()
    
    useEffect(()=>{
        const logged = async() => {
            if(!localStorage.getItem('biblioteca-acreditar-token')){
                return
            }
            toast.info('Logando...',{
                theme:'dark',
                toastId: 'email'
            })
            const resp = await auth.logged()
            
            if(resp){
                
                if(auth.lastRoute == '/' || auth.lastRoute == ''){
                    
                    return navigate('/dashboard')
                }
                return navigate(auth.lastRoute)
            }
        }
        logged()
    },[])

    const handleLogin = async() => {

        if(email === ''){
            toast.info('Preencha o e-mail.',{
                theme:'dark',
                toastId: 'email'
            })
            return
        }
        if(password === ''){
            toast.info('Preencha a senha.',{
                theme:'dark',
                toastId: 'password'
            })
            return
        }

        toast.info('Carregando...',{
            theme:'dark',
            toastId: 'load'
        })
    
        const data = await auth.signin(email,password)

        if(data){
            console.log(auth.lastRoute)
            if(auth.lastRoute == '/' || auth.lastRoute == ''){
                return navigate('/dashboard')
            }
            return navigate(auth.lastRoute)
        }else{
            toast.error('Usuário ou senha incorretos',{
                theme:'dark',
                toastId: 'errorLogin'
            })
        }


    }

    async function handleUpdatePass(){

        if(email == ''){
            toast.error('Preencha o E-mail', {theme: 'dark', toastId: 'errorEmail'})
            return
        }

        toast.loading('Carregando...', {theme: 'dark', toastId: 'attPass'})

        const data = await api.resetPassword(email)
        
        if(data == 'passwords.sent'){
            toast.dismiss('attPass')
            toast.info('Acesse seu e-mail para trocar a senha', {theme: 'dark', toastId: 'att'})
        }else if(data == 'passwords.throttled'){
            toast.dismiss('attPass')
            toast.info('Acesse seu e-mail para trocar a senha', {theme: 'dark', toastId: 'att'})
        }else{
            toast.dismiss('attPass')
            toast.error('E-mail inválido', {theme: 'dark', toastId: 'att'})
        }
  
    }

    function handleEnter(){

        document.addEventListener('keyup', e => {
            if(e.key === 'Enter'){
                if(email !== '' && password !== ''){
                    ref.current.click()
                }
            }
        })
    }

    handleEnter()


    return(
        <div style={{backgroundColor:'#181846'}} className="flex items-center justify-center overflow-hidden w-screen h-screen">
            <video style={{minWidth:'1500px'}} className="w-screen" loop autoPlay muted>
                <source src={`../../assets/videos/Biblioteca_baixa.mp4`} type="video/mp4"/>
            </video>
            <div className="absolute flex flex-col">
                <div style={{maxWidth:'450px', minWidth:'300px'}} className="flex items-center justify-center">
                    <img src="../../assets/images/bg-form.png" alt="imagem" style={{width:'450px', height:'400px'}}/>
                    <div className="absolute w-full h-full p-5">
                        <div className={`w-full flex flex-col justify-center items-center  `}>
                            <div className="">
                                <img className="w-36" src="../../assets/images/Logo.svg" alt=""/>
                            </div>
                            <div className="mt-4 flex items-center " >
                                <h1 style={{fontFamily:'Gruppo'}} className="text-4xl text-white font-semibold">Biblioteca</h1>
                            </div>
                        </div>
                        <div style={{fontFamily:'Montserrat'}} className="flex flex-col w-11/12 m-auto ">
                            <input type="text" placeholder="E-mail" className="mt-4 p-2  rounded-lg" onChange={(e) => setEmail(e.target.value)} value={email}/>
                            <input type="password" placeholder="Senha" className="mt-5 p-2  rounded-lg" onChange={(e) => setPassword(e.target.value)} value={password}/>
                            <button ref={ref} className="mt-5 p-2 bg-cyan-600 rounded-lg text-white font-semibold hover:bg-cyan-700" onClick={handleLogin}>
                                Entrar
                            </button>
                            <span className="text-center mt-8 text-white font-light ">Esqueceu sua senha?  
                            <button className='underline pl-2' onClick={handleUpdatePass}> Clique aqui.</button></span>
                            {/* <a href="/" className="text-center mt-10 text-white font-light underline italic"><p>Esqueceu sua senha? Clique aqui.</p></a> */}
                            {/* <button className="bg-white mt-12 underline w-1/3 m-auto p-1 rounded-2xl text-cyan-600 font-semibold">
                                Criar Conta
                            </button> */}
                        </div>
                    </div>
                </div>

            </div>
            <ToastContainer/>
            <a href="https://acreditarbanco.com.br/" target="_blank" rel="noreferrer" className="text-center text-white font-light absolute bottom-3">
                    Acesse o site do Acreditar Banco
            </a>
        </div>
    )
} 