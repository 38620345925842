import Footer from "../../components/footer"
import Menu from "../../components/menu"
import useAuth from "../../hooks/useAuth"

import { keepPreviousData, useInfiniteQuery } from "@tanstack/react-query"
import { authApi } from "../../services/api/api"

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import PostAddIcon from '@mui/icons-material/PostAdd';
import DoneAllIcon from '@mui/icons-material/DoneAll';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import { useEffect, useRef, useState } from "react"
import Post from "../../components/post"
import { ToastContainer, toast } from "react-toastify"

import { useInView } from 'react-intersection-observer';
import SkeletonIndications from "../../components/skeletons/indications"

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextEditor from "../../components/textEditor"


export default function Forum(){

    const { ref, inView } = useInView();

    const api = authApi()

    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');

    const [users, setUsers] = useState([]);

    const [label, setLabel] = useState(false);

    useEffect(()=>{
        async function getUsers(){
            const data = await api.selectUser()
            setUsers(data)
            setLabel(true)
        }
        getUsers()
    },[])

    const [start, setstart] = useState('')
    const [end, setEnd] = useState('')

    const [filtro, setFiltro] = useState(null)

    const { user, theme }  = useAuth()

    const [comment, setComment] = useState(false)

    const [page, setPage] = useState(0)

    const [lastPage, setlastPage] = useState(false)

    const [atualizar, setAtualizar] = useState(false)

    const [textNewPost, setTextNewPost] = useState('')

    const inputNewPost = useRef(null)
    
    const [imgNewPost, setImgNewPost] = useState('');

    const [titleBook, setTitleBook] = useState('');

    const [open, setOpen] = useState(false);

    const handleClickOpen = async() => {
        setOpen(true);
    };
    
    const handleClose = () => {
      setOpen(false);
    };

    const [openNewPost, setOpenNewPost] = useState(false);

    const handleClickOpenNewPost = async() => {
        setOpenNewPost(true);
    };
    
    const handleCloseNewPost = () => {
        setOpenNewPost(false);
    };


    const getData = async({pageParam}) => {

        setlastPage(pageParam.last_pages)
        
        const data = await api.getPosts(pageParam.nextPage, filtro)

        return data 
    }

    const {data, fetchNextPage, hasNextPage, isFetchingNextPage} = useInfiniteQuery({
        queryKey: ['indications',comment, atualizar, filtro],
        queryFn: getData,
        // placeholderData:keepPreviousData, 
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPages) => { 

            const nextPage = allPages.length + 1 

            let last_pages = false

            if(nextPage >= lastPage.last_page){
                last_pages = true
            }else{
                last_pages = false
            }
    
            return {nextPage, last_pages}

        }
    })

    useEffect(() => {

        if(inView && !lastPage){
            fetchNextPage()
        }
    }, [inView, fetchNextPage, lastPage,isFetchingNextPage])


    async function handleSetPostComment(data:any){

        const response = await api.setCommentPost(data)

        if(response === 200){
            setComment(!comment)
        }
        
    }

    function handleFilter(){
        setFiltro({start,end,inputValue})
        handleClose()
    }


    async function handleSetImgPost(){
        if(inputNewPost){
            inputNewPost.current?.click()
        }
    }

    function handleSetImage(e: React.ChangeEvent<HTMLInputElement>){

        const selectedFiles = e.target.files

        if(selectedFiles?.[0].size > 1048576){
            toast.error('A imagem não pode ultrapassar 1mb.',{theme:'dark',toastId: 'password'})
            return
        }

        if(e.target.files){

            const files = e.target.files[0]

            const reader = new FileReader()
            reader.readAsBinaryString(e.target.files[0])
            reader.onload = (ev:any) => {
                setImgNewPost(`data:${files.type};base64,${btoa(ev.target.result)}`)
            }
        }
    }

    async function handleSetNewPost(){

        if(textNewPost == '' || imgNewPost == '' || titleBook == ''){
            toast.error('Adicione uma capa de livro e um texto.',{theme:'dark',toastId: 'errorNewPost'})
            return
        }

        toast.loading('Carregando...',{theme:'dark',toastId: 'sendPost'})

        const dados = {text:textNewPost,img:imgNewPost,user:user.id, title:titleBook}

        const result = await api.setNewPost(dados)

        if(result){
            toast.dismiss('sendPost')
            toast.dismiss('errorNewPost')
            toast.success('Post criado com sucesso!',{theme:'dark',toastId: 'sendPostsuccess'})
            setAtualizar(old => !old )
        }else{
            toast.dismiss('sendPost')
            toast.dismiss('errorNewPost')
            toast.error('Houve algum erro, tente novamente mais tarde',{theme:'dark',toastId: 'sendPosterror'})
        }
        handleCloseNewPost()

    }

    return(
        <div className={`main w-screen bg-gray-100 ${theme} overflow-x-hidden`}>
            <Menu/>
            <div className="content w-screen min-h-screen flex justify-center dark:bg-slate-800">
                <div className="sm:w-4/5 w-full flex justify-center mb-8 p-8">
                    <div className="w-full ">
                        <div className="dark:text-white flex justify-between">
                            <h1>Fórum</h1>
                            <div>
                                <button onClick={handleClickOpenNewPost}><PostAddIcon/></button>
                                <button onClick={handleClickOpen}><FilterAltIcon/></button>
                            </div>
                        </div>
                            <hr className="border-gray-200 border-1"/>
                        {data?.pages ? (data?.pages.map((items) => items.data.map((item) => <Post atualizar={atualizar} setAtualizar={setAtualizar} key={item.id} post={item} handleSetPostComment={handleSetPostComment}/>)))
                        : <SkeletonIndications/>}
                        {isFetchingNextPage ? <SkeletonIndications/> : ''}
                        <span ref={ref} className="text-gray-400"></span>
                    </div>
                </div>
            </div>
            <ToastContainer/>
            {/* <Footer/> */}
            <div className="w-full h-8 dark:bg-slate-800"></div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Filtrar."}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div className="p-4">
                    <Autocomplete
                        value={value}
                        onChange={(event: any, newValue: string | null) => {
                        setValue(newValue);
                        
                        }}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                        }}
                        id="controllable-states-demo"
                        options={users?.map((item)=> item.email)}
                        sx={{ width: 200 }}
                        renderInput={(params) => <TextField {...params} label={label ? 'Usuários' : <CircularProgress size={20}/>} />}
                    />
                    </div>
                    <div className="p-4">
                        <div className="flex flex-col">
                            <label>Data inicial:</label>
                            <input type="date"  className="p-1 border rounded border-gray-400" onChange={(e) => setstart(e.target.value)}/>
                        </div>
                        <div className="flex flex-col mt-4">
                            <label>Data final:</label>
                            <input type="date"  className="p-1 border rounded border-gray-400" onChange={(e) => setEnd(e.target.value)}/>
                        </div>
                    </div>
                </DialogContentText>    
                </DialogContent>
                <DialogActions>
                <Button autoFocus onClick={handleFilter}>
                    Filtrar
                </Button>
                <Button onClick={handleClose} color="error" autoFocus>
                    Cancelar
                </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openNewPost}
                onClose={handleCloseNewPost}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Escrever Post de um livro externo."}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {/* <textarea onChange={(e)=> setText(e.target.value)} className="bg-gray-100" style={{minWidth:'450px', minHeight:'120px'}}></textarea> */}
                    <TextEditor setText={setTextNewPost}/>
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <input type="text" placeholder="Titulo do livro" className="border rounded w-1/2" onChange={e => setTitleBook(e.target.value)} value={titleBook}/>
                <Button color={imgNewPost != '' ? 'success' : 'error'} onClick={handleSetImgPost} autoFocus>
                    {imgNewPost != '' ? 'Capa selecionada' : 'Selecione uma capa'} {imgNewPost != '' ? <DoneAllIcon/> : ''}
                    <input type="file" accept="image/*" className="hidden" ref={inputNewPost} onChange={e => handleSetImage(e)}/>
                </Button>
                <Button onClick={handleSetNewPost} autoFocus>
                    Enviar
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}