import Footer from "../../components/footer"
import Menu from "../../components/menu"
import useAuth from "../../hooks/useAuth"


import { useInfiniteQuery } from '@tanstack/react-query'

import { authApi } from "../../services/api/api";

import { toast, ToastContainer } from "react-toastify";
import SkeletonIndications from "../../components/skeletons/home/indications";
import { useState } from "react";
import LastIndications from "../../components/lastIndications";
import LastBooksHome from "../../components/lastBooksHome";

export default function Dashboard(){

    const api = authApi()

    const { user, theme }  = useAuth()

    //------------------------------------------last indications-----------------------------


    const getLastIndications = async({pageParam}) => {

        const data = await api.getIndications(pageParam.nextPage, 4)
        return data 
    }

    let last_pages = false
    let last_pages1 = false

    const {data:lastIndications, fetchNextPage, hasNextPage, isFetchingNextPage} = useInfiniteQuery({
        queryKey: ['lastIndications'],
        queryFn: getLastIndications,
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPages) => { 

            const nextPage = allPages.length + 1 

            

            if(nextPage >= lastPage.last_page){
                last_pages = true
            }else{
                last_pages = false
            }

            return {nextPage, last_pages}

        }
    })

    //------------------------------------------last indications-----------------------------


    const getLastBooks = async({pageParam}) => {
        
        const data = await api.getBooks(pageParam.nextPage)
        return data 
    }

    const {data:lastBooks, fetchNextPage:fetchNextPageBooks, isFetchingNextPage:loadNest } = useInfiniteQuery({
        queryKey: ['lastBooks'],
        queryFn: getLastBooks,
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPages) => { 

            const nextPage = allPages.length + 1 



            if(nextPage >= lastPage.last_page){
                last_pages1 = true
            }else{
                last_pages1 = false
            }

            return {nextPage, last_pages1}

        }
    })
    

    return(
        <div className={`main w-screen bg-gray-100 ${theme}`}>
            <Menu/>
            <div className="content w-screen min-h-screen flex justify-center dark:bg-slate-800">
                <div className="w-full sm:w-4/5 flex justify-center">
                    <div className="w-full p-8 ">
                        <div>
                            <h1 className="dark:text-white">Últimas Indicações</h1>
                            <hr />
                        </div>
                        <div className="mt-4  rounded p-2 flex flex-wrap gap-4 justify-center w-full">
                            {lastIndications?.pages ? (lastIndications?.pages.map((items) => items.data.map((item) => <LastIndications key={item.id} item={item}/>))) : <SkeletonIndications/>}
                            {isFetchingNextPage ? <SkeletonIndications/> : ''}
                        </div>
                            <div className="w-full flex justify-end my-2">
                                { !last_pages &&
                                    <button onClick={() => fetchNextPage()} className={`dark:bg-slate-700 dark:hover:bg-slate-600 bg-gray-300 hover:bg-gray-400 rounded p-1 `}>
                                        Carregar mais
                                    </button>
                                }
                            </div>
                            
                        <div className="mt-8 w-full">
                            <div>
                                <h1 className="dark:text-white">Últimos Livros Adicionados</h1>
                                <hr />
                            </div>
                            <div className="mt-4  rounded p-2 flex flex-wrap gap-4 justify-center w-full" >
                                {lastBooks?.pages ? (lastBooks?.pages.map((items) => items.data.map((item) => <LastBooksHome key={item.id} item={item}/>))) : <SkeletonIndications/>}
                                {loadNest ? <SkeletonIndications/> : ''}
                            </div>
                            <div className="w-full flex justify-end my-2">
                                { !last_pages1 &&
                                    <button onClick={() => fetchNextPageBooks()} className={`dark:bg-slate-700 dark:hover:bg-slate-600 bg-gray-300 hover:bg-gray-400 rounded p-1 `}>Carregar mais</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer/>
            {/* <Footer/> */}
        </div>
    )
}