import { useState } from "react"
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { ToastContainer, toast } from "react-toastify"


import styles from './styles.module.css'
import { authApi } from "../../services/api/api"
import useAuth from "../../hooks/useAuth"

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Comment from "../comment"
import TextEditor from "../textEditor";

export default function Indications({item, handleComment, atualizar, setAtualizar}:any){

    const api = authApi()

    const { user, theme }  = useAuth()

    const [likes, setLikes] = useState(item.likes)

    const [liked, setLiked] = useState(item.like ? true : false)

    const [text, setText] = useState(item.description ?? '')

    const [textComment, setTextComment] = useState('')

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const [open1, setOpen1] = useState(false);

    const handleClickOpen1 = () => {
      setOpen1(true);
    };
  
    const handleClose1 = () => {
      setOpen1(false);
    };

    const [open2, setOpen2] = useState(false);

    const handleClickOpen2 = () => {
      setOpen2(true);
    };
  
    const handleClose2 = () => {
      setOpen2(false);
    };

    async function setComment(){
        toast.loading('Carregando...',{theme:'dark',toastId: 'load_comment'})
        if(user){
            const data = {
                'user': user.id,
                'indication': item.id,
                'text': textComment
            } 

            await handleComment(data)
        }
        handleClose()
        toast.dismiss('load_comment')
    }
    
    async function handleLikeIndication(){


        if(user){
            const response = await api.likeIndication(item.id, user.id)
            
            if(response === 200){
                setLiked(true)
                setLikes(likes + 1)
            }else{
                setLiked(false)
                setLikes(likes - 1)
            }
        }
    }

    async function handleDelete(){

        const response = await api.destroyIndication(item.id)
        

        if(response == 200){
            toast.success('Publicação excluida com sucesso.',{theme:'dark',toastId: 'deleteSuccsess'})
            setAtualizar(!atualizar)
            handleClose1()
            return
        }
        handleClose1()
        toast.info('Não foi possível excluir a publicação.',{theme:'dark',toastId: 'errorDelete'})

    }

    async function handleUpdate(){

         const data = {
            id: item.id,
            text: text
         }

         toast.loading('Carregando.',{theme:'dark',toastId: 'loadUpdate'})

         const response = await api.updateIndication(data)

         if(response == 200){
            toast.dismiss('loadUpdate')
            setAtualizar(!atualizar)
            handleClose2()
         }else{
            toast.dismiss('loadUpdate')
            toast.error('Não foi possivel atualizar a indicação.',{theme:'dark',toastId: 'erroUpdate'})
         }

    }

    return(
        <div className={`w-full flex justify-center m-auto flex-col dark:bg-gray-700 p-4 my-2 rounded bg-gray-300`}>
            <div className=" flex items-center gap-2">
                <img src={item.user.img} alt="foto do usuario"  width={50} className="rounded-full border-2 dark:border-blue-700 border-white"/>
                <h1 className="text-sm text-blue-950 dark:text-white"><strong>{item.user.name}</strong> <small>Fez uma indicação.</small></h1>
                {item.user_id == user.id ? 
                <div className="flex gap-2"><button className="text-red-500" onClick={handleClickOpen1}><DeleteForeverIcon fontSize="small"/></button>
                <button className="text-blue-800 dark:text-white" onClick={handleClickOpen2}><EditIcon fontSize="small"/></button></div> 
                : ''}
            </div> 
            <div className="flex mb-2 w-full ">
                <div className="">
                    <div className="bg-gray-400" style={{width:'2px', height:'80px', margin:'5px 25px 0px 25px'}}></div>
                    <div className="bg-gray-400" style={{width:'50px', height:'2px', marginLeft:'25px', marginRight:'5px'}}></div>
                    <div className="mt-9 ml-6">
                        <button className="flex items-center gap-1" onClick={handleLikeIndication}>
                            <small className="mt-1">{likes}</small>
                            <div className="text-blue-700 dark:text-white">
                                {liked ? <ThumbUpAltIcon/> : <ThumbUpOffAltIcon/>}
                            </div>
                        </button>
                    </div>
                </div>
                <div className={`flex gap-2 w-full ${styles.displayColumn}`}>
                    <div className="">
                        <img src={item.book.img} alt="capa do livro" style={{width:'100px'}} />
                        <Button onClick={handleClickOpen} variant="text" size='small'>Comentar</Button>
                    </div>
                </div>
            </div>

            <div className="w-full  sm:pl-4 sm:ml-0 mb-4 " >
                <p className="text-blue-950 flex flex-col font-semibold dark:text-white" style={{textTransform:'capitalize'}}>{item.book.title} <small>{item.book.autor}</small></p>
                
                <div className={`overflow-auto ${styles.scroll} p-1 bg-gray-100 rounded dark:bg-gray-600 w-full`} style={{ maxHeight:'350px'}}>
                    <div className="w-full " dangerouslySetInnerHTML={{__html: item.description}}></div>
                    {/* <p className="text-black">{item.description}</p> */}
                </div>
            </div>

            {item?.comment?.length > 0 && item.comment.map((item:any) => <Comment atualizar={atualizar} setAtualizar={setAtualizar} key={item.id} item={item} />)}

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Escreva seu comentário."}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {/* <textarea onChange={(e)=> setText(e.target.value)} className="bg-gray-100" style={{minWidth:'450px', minHeight:'120px'}}></textarea> */}
                    <div className="border">
                        <TextEditor  setText={setTextComment}/>
                    </div>
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={setComment} autoFocus>
                    Enviar
                </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={open1}
                onClose={handleClose1}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Excluir comentário."}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <h1>Tem certeza que deseja excluir a indicação?</h1>
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleDelete} autoFocus color="error">
                    Deletar
                </Button>
                <Button onClick={handleClose1} autoFocus >
                    Cancelar
                </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={open2}
                onClose={handleClose2}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Atualizar Indicação."}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div className="border">
                        {/* <textarea value={text} onChange={(e)=> setText(e.target.value)} className="bg-gray-100" style={{minWidth:'450px', minHeight:'120px'}}></textarea> */}
                        <TextEditor text={text} setText={setText}/>
                    </div>
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleUpdate} autoFocus>
                    Atualizar
                </Button>
                <Button onClick={handleClose2} autoFocus >
                    Cancelar
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}