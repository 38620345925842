import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import { useNavigate } from 'react-router-dom';

export default function LastIndications({item}:any){

    const navigate = useNavigate()

    return (
        <div onClick={() => navigate('/indicacoes', {state:true})} key={item} style={{maxWidth:'350px', height:'170px'}} className='w-full bg-gray-200 rounded flex p-2 gap-2 cursor-pointer hover:shadow-2xl dark:hover:border-sky-500 border-2'>
            <div className='w-full overflow-hidden' style={{maxWidth:'200px'}}>
                <div className='flex justify-start gap-2 items-center'>
                    <div style={{width:'50px', height:'50px'}} className="rounded-full bg-white flex items-center justify-center border-2 border-white">
                        <img src={item?.user.img} alt="imagem do usuario" className="rounded-full" width={50}/>
                    </div>
                    <h3 >{item?.user.name}</h3>
                </div>
                <div className={`px-2 overflow-hidden`} style={{maxWidth:'250px'}}>
                    <h3 className="flex gap-2 whitespace-nowrap"><small><strong>Titulo:</strong></small><small className='whitespace-nowrap' title={item?.book.title} style={{textTransform:'capitalize'}}>{item?.book.title}</small></h3>
                </div>
                <div className={`px-2 overflow-hidden`} style={{maxWidth:'250px'}}>
                    <h3 className="flex gap-2 whitespace-nowrap"><small><strong>Autor:</strong></small><small title={item?.book.autor} style={{textTransform:'capitalize'}}>{item?.book.autor}</small></h3>
                </div>
                <div className="px-2">
                    <h3 className="flex gap-2"><small><strong>Editora:</strong></small><small className='whitespace-nowrap' style={{textTransform:'capitalize'}}>{item?.book.editora}</small></h3>
                </div>
                <div className='flex items-end px-2 gap-1'>
                    <span className='text-blue-700 '>{item?.likes > 0 ? <ThumbUpAltIcon/> : <ThumbUpOffAltIcon/>}</span>
                    <span>{item?.likes}</span>
                </div>
            </div>
            <div className='' style={{width:'100px', height:'150px'}}>
                <img src={item?.book.img} alt="imagem do livro"  className="rounded"/>
            </div>
    </div>
    )
}