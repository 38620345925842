import Skeleton from '@mui/material/Skeleton';

export default function SkeletonMiniBook(){
    return(
        <div>
            <div className="max-w-xs flex items-center dark:bg-gray-600 rounded p-2 gap-4 bg-white" style={{width:'300px', height:'170px'}}>
                <div className=''>
                    <Skeleton variant="rounded" width={100} height={150} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                </div>
                <div className=" flex flex-col justify-between">
                    <div>
                        <Skeleton variant="text" width={150} height={40} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                        <Skeleton variant="text" width={150} height={40} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                    </div>
                    <div className="">
                        <Skeleton variant="text" width={150} height={40} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                        <Skeleton variant="text" width={150} height={40} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                    </div>
                </div>
            </div>
        </div>
    )
}