import { useNavigate } from 'react-router-dom';


export default function MiniPerfil({user}){

    const navigate = useNavigate()

    let name = user.name.split(' ')
    if(name[0].length > 6){
        name = name[0].substring(0,5) + '...'
    }else{
        name = name[0]
    }

    return (
        <div className='border p-1 rounded border-gray-600'>
           <button onClick={() => navigate('/usuario', {state: user})}>
                <img src={user.img} width={50} className='rounded-full'/>
                <small>{name}</small>
           </button>
        </div>
    )
}