import moment from "moment"
import { authApi } from "../../services/api/api"
import { toast } from "react-toastify"
import useAuth from "../../hooks/useAuth"

export default function MiniBookUser({item, atualizar, setAtualizar}:any){

    const api = authApi()

    const {user, theme} = useAuth()

    const start = moment(item?.start).format('DD/MM/YYYY')

    const end = moment(item?.finaly).format('DD/MM/YYYY')

    
    async function handleClickDismiss(){
        toast.loading('Carregando.',{theme:'dark',toastId: 'dismissLoad'})
        const response = await api.dismissBooksUser(item.id)
        console.log(response)
        if(response == 200){
            toast.success('Livro entregue com sucesso.',{theme:'dark',toastId: 'dismiss'})
            toast.dismiss('dismissLoad')
            setAtualizar(!atualizar)
            return
        }
        toast.dismiss('dismissLoad')
        toast.error('Não foi possivel entregar o livro.',{theme:'dark',toastId: 'dismiss-error'})

    }
    

    return (
        <div>
            <div className="max-w-xs flex dark:bg-gray-600 rounded p-2 gap-4 bg-white" style={{width:'300px', height:'170px'}}>
                <div>
                    <img src={item?.books.img} alt="imagem do livro" width={100} height={200} />
                </div>
                <div className="py-2 flex flex-col justify-between">
                    <div className="overflow-auto max-h-20">
                        <h1 className="font-semibold" style={{textTransform:'capitalize', maxWidth:'150px'}}>{item?.books.title}</h1>
                        <p style={{textTransform:'capitalize'}}>{item?.books.autor}</p>
                    </div>
                    <div className="">
                        <small className="flex">Retirado: {start}</small>
                        <small className="flex">Entregue: {end}</small>
                    </div>
                    {item?.status == 'reservado' && user.admin == 1 ? 
                    <button onClick={handleClickDismiss} className="bg-blue-900 rounded text-white hover:bg-blue-600"><small>Finalizar</small></button> : ''}
                </div>
            </div>
        </div>
    )
}