import { createContext } from "react";
import { userInterface } from "../../interfaces/userInterface";

export type AuthContextType = {
    user: userInterface | null
    signin: (email:string, password:string) => Promise<boolean>
    signout: () => void
    logged: () => Promise<boolean>
    theme: string
    toggleTheme: (theme:string) => void
    storeBook: (form:object) => any
    lastRoute: string
    setLastRoute: (route:string) => void
    setUser: (userInterface) => void
}

export const AuthContext = createContext<AuthContextType>(null!)