import React from 'react';
import './App.css';
import IsAuth from './contexts/Auth/IsAuth';
import Login from './pages/login';

import { Routes, Route } from 'react-router-dom'
import Dashboard from './pages/dashboard';
import Biblioteca from './pages/biblioteca';

import MeusLivros from './pages/meusLivros';
import Indicacoes from './pages/indicacoes';
import Forum from './pages/forum';
import AdicionarLivro from './pages/adicionarLivro';


import Indicacacao from './pages/indicacao';
import Usuarios from './pages/usuarios';
import Usuario from './pages/usuario';
import IsAdmin from './contexts/Auth/isAdmin';
import Profile from './pages/perfil';




function App() {

  return (
    <div className='bg-slate-800'>
      <Routes>
        <Route path='/' element={<Login />}/>

        <Route path='/dashboard' element={<IsAuth><MeusLivros/></IsAuth>}/>
        <Route path='/biblioteca' element={<IsAuth><Biblioteca/></IsAuth>}/>

        <Route path='/meus-livros' element={<IsAuth><Dashboard/></IsAuth>}/>
        <Route path='/forum' element={<IsAuth><Forum/></IsAuth>}/>
        <Route path='/indicacao' element={<IsAuth><Indicacacao/></IsAuth>}/>
        <Route path='/perfil' element={<IsAuth><Profile/></IsAuth>}/>

        <Route path='/indicacoes' element={<IsAuth><Indicacoes/></IsAuth>}/>


        <Route path='/adicionar-livro' element={<IsAdmin><AdicionarLivro/></IsAdmin>}/>
        <Route path='/usuarios' element={<IsAdmin><Usuarios/></IsAdmin>}/>
        <Route path='/usuario' element={<IsAuth><Usuario/></IsAuth>}/>

        <Route path='*' element={<Login />}/>        
      </Routes>
    </div>
  )
  // return (<IsAuth/>)
}

export default App;
