import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../../components/footer";
import Menu from "../../components/menu";
import useAuth from "../../hooks/useAuth";
import styles from './adicionarLivro.module.css'
import { useRef, useState } from "react";

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { authApi } from "../../services/api/api";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import AddBoxIcon from '@mui/icons-material/AddBox';


export default function AdicionarLivro(){

    const api = authApi()

    const {state} = useLocation()

    const navigate = useNavigate()


    const { user, theme, storeBook }  = useAuth()

    const [file, setfile] = useState(state ? state.img : '')
    const [image, setImage] = useState(null)

    const [gen, setGen] = useState(state ? state.gen : '')
    const [title, setTitle] = useState(state ? state.title : '')
    const [autor, setAutor] = useState(state ? state.autor : '')
    const [cod, setCod] = useState('')
    const [edition, setEdition] = useState(state ? state.edition : '')
    const [editora, setEditora] = useState(state ? state.editora : '')
    const [year, setYear] = useState(state ? state.year : '')
    const [exemplares, setExemplares] = useState(state ? state.exemplaries.length : '')
    const [summary, setSummary] = useState(state ? state.summary : '')
 
    let inputRef = useRef<HTMLInputElement>(null)

    
    const [open, setOpen] = useState(false);


    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    function handleImg(){
        if(inputRef){
            inputRef.current?.click()
        }
    }

    function handleFile(e: React.ChangeEvent<HTMLInputElement>){
        const selectedFiles = e.target.files as FileList

        if(selectedFiles?.[0].size > 1048576){
            toast.error('A imagem não pode ultrapassar 1mb.',{theme:'dark',toastId: 'password'})
            return
        }

        setfile(URL.createObjectURL(selectedFiles?.[0]))

        setImage(e.target.files[0])

        if(e.target.files){

            const files = e.target.files[0]


            const reader = new FileReader()
            reader.readAsBinaryString(e.target.files[0])
            reader.onload = (ev:any) => {
                setImage(`data:${files.type};base64,${btoa(ev.target.result)}`)
            }
        }
    }

    async function handleSave(e:any){
        e.preventDefault()

        if(gen === ''){
            toast.info('Selecione o Genêro.',{theme:'dark',toastId: 'password'})
            return
        }
        if(title === ''){
            toast.info('Insira o Título.',{theme:'dark',toastId: 'password'})
            return
        }
        if(autor === ''){
            toast.info('Insira o Autor.',{theme:'dark',toastId: 'autor'})
            return
        }
        if(edition === ''){
            toast.info('Insira a Edição.',{theme:'dark',toastId: 'edition'})
            return
        }
        if(editora === ''){
            toast.info('Insira a Editora.',{theme:'dark',toastId: 'editora'})
            return
        }
        if(year === ''){
            toast.info('Insira o Ano.',{theme:'dark',toastId: 'year'})
            return
        }
        if(exemplares === ''){
            toast.info('Insira a quantidade de exemplares.',{theme:'dark',toastId: 'exemplares'})
            return
        }
        if(summary === ''){
            toast.info('Insira o resumo.',{theme:'dark',toastId: 'summary'})
            return
        }

        if(file === ''){
            toast.info('Insira a imagem de capa.',{theme:'dark',toastId: 'image'})
            return
        }

        const data = {
            'gen' : gen,
            'title' : title,
            'autor': autor,
            'editora': editora,
            'cod': cod,
            'edition': edition,
            'year': year,
            'exemplares': exemplares,
            'summary': summary,
            'image': image,
            'id': user?.id,
            'book_id': state?.id ?? ''
        }


        if(state){
            const resp = await api.updateBook(data)
            console.log(resp)
            
            if(resp.status === 200){
                toast.success(resp.message,{theme:'dark',toastId: 'success'})
            }else{
                toast.error(resp.message,{theme:'dark',toastId: 'error'})
            }

        }else{

            const resp = await storeBook(data)
            console.log(resp)
            if(resp.status == 200){
                toast.success(resp.message,{theme:'dark',toastId: 'success'})
            }else{
                toast.error(resp.message,{theme:'dark',toastId: 'error'})
            }
        }
    }

    async function handleDelete(){
        
        const response = await api.deleteBook(state.id)

        if(response == 200){
            toast.success('Livro excluido com sucesso!',{theme:'dark',toastId: 'successDelete'})
            handleClose()
            navigate('/biblioteca')
        }else{
            toast.error('Não foi possivel excluir o livro.',{theme:'dark',toastId: 'errorDelete'})
            handleClose()
        }
    }

    return(
        <div className={`${theme} dark:bg-slate-800 bg-gray-100 overflow-x-hidden`}>
            <Menu/>
            <form action="" method="POST">
                <div className={`w-screen flex justify-center items-center ${theme} ${styles.content} dark:bg-slate-800 bg-gray-100`}>
                    <div className="w-screen flex justify-center dark:bg-slate-800 dark:text-white items-center bg-gray-100">
                        <div className=" w-screen flex gap-6 justify-center flex-col items-center mb-4" style={{maxWidth:'800px'}}>
                            <div className={`flex gap-10 mt-4 justify-center items-center px-2 w-full ${styles.displayColumn}`}>
                                <div className={`flex flex-row ${styles.moldura} w-full`}>
                                    <button type="button" className={`bg-gray-200 rounded-lg w-full`} onClick={handleImg}> <span className={`${file === '' ? '' : 'hidden'} text-gray-400`}>Selecionar imagem 300x500</span>
                                        <img src={file} alt="imagem livro" className={`bg-gray-200 w-full flex items-center justify-center text-gray-500 rounded-lg ${file === '' ? 'hidden' : styles.moldura}`}/>
                                    </button>
                                    <input name="image" type="file" accept="image/*" className="hidden" ref={inputRef} onChange={(e) => {handleFile(e)}}/>
                                </div>
                                <div className="flex flex-col justify-between w-full">
                                    <div className={`${styles.inputs}  my-2 sm:p-0 flex flex-col`}>
                                        <label> Titulo do livro:</label>
                                        <input style={{textTransform:'uppercase'}} name="title" type="text" className="rounded-lg bg-gray-200 p-2 dark:text-gray-900" onChange={(e) => setTitle(e.target.value)} value={title}/>
                                    </div>
                                    <div className={`${styles.inputs} sm:p-0 my-2 `}>
                                        <div className={`flex gap-2`}>
                                            <div className="flex flex-col w-1/3">
                                                {/* <label>Código do livro:</label>
                                                <input name="cod" type="text" className="rounded-lg bg-gray-200 p-2 dark:text-gray-900" onChange={(e) => setCod(e.target.value)} value={cod}/> */}
                                                <label htmlFor="">Genêro</label>
                                                <select value={gen} className="p-2 rounded-lg dark:text-black bg-gray-200" onChange={(e) => setGen(e.target.value)}>
                                                    <option value=""></option>
                                                    <option value="Ação e aventura">Ação e aventura</option>
                                                    <option value="Auto Ajuda">Auto Ajuda</option>
                                                    <option value="Arte e Fotografia">Arte e Fotografia</option>
                                                    <option value="Auto Biografia">Auto Biografia</option>
                                                    <option value="Biografia">Biografia</option>
                                                    <option value="Conto">Conto</option>
                                                    <option value="Fantasia">Fantasia</option>
                                                    <option value="Fábula">Fábula</option>
                                                    <option value="Ficção Científica">Ficção Científica</option>
                                                    <option value="Filosofia">Filosofia</option>
                                                    <option value="Horror">Horror</option>
                                                    <option value="Romance">Romance</option>
                                                    <option value="Infantil">Infantil</option>
                                                    <option value="História">História</option>
                                                    <option value="Humor">Humor</option>
                                                    <option value="Religião e Espiritualidade">Religião e Espiritualidade</option>
                                                    <option value="Ciências Sociais">Ciências Sociais</option>
                                                    <option value="Tecnologia e Ciência">Tecnologia e Ciência</option>
                                                    <option value="Negócios">Negócios</option>
                                                </select>
                                            </div>
                                            <div className="flex flex-col w-full">
                                                <label>Autor:</label>
                                                <input style={{textTransform:'uppercase'}} name="autor" type="text" className="rounded-lg bg-gray-200 p-2 dark:text-gray-900" onChange={(e) => setAutor(e.target.value)} value={autor}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${styles.inputs} sm:p-0 my-2 flex flex-col`}>
                                        <label> Edição:</label>
                                        <input name="edition" type="text" className="rounded-lg bg-gray-200 p-2 dark:text-gray-900" onChange={(e) => setEdition(e.target.value)} value={edition}/>
                                    </div>
                                    <div className={`${styles.inputs} sm:p-0 my-2 flex flex-col`}>
                                        <label> Editora:</label>
                                        <input style={{textTransform:'uppercase'}} name="editora" type="text" className="rounded-lg bg-gray-200 p-2 dark:text-gray-900" onChange={(e) => setEditora(e.target.value)} value={editora}/>
                                    </div>
                                    <div className={`flex flex-row gap-4 sm:p-0 my-2 `}>
                                        <div className="flex flex-col w-1/2 xl:w-1/2">
                                            <label> Ano:</label>
                                            <input name="year" type="date" className="rounded-lg bg-gray-200 p-2 w-full dark:text-gray-900" onChange={(e) => setYear(e.target.value)} value={year}/>
                                        </div>
                                        <div className={`flex items-end ${state ? 'w-1/4' : 'w-1/2'} xl:w-1/2`}>
                                            <div className="flex flex-col">
                                            <label>Exemplares:</label>
                                            <input readOnly={state ? true : false} type="number" name="exemplares" id="" className="bg-gray-200 w-full p-2 rounded-lg dark:text-gray-900" onChange={(e) => setExemplares(e.target.value)} value={exemplares}/>
                                            </div>
                                        <div>
                                            {state ? <Button onClick={() => setExemplares((old) => old +1)}><AddBoxIcon/></Button> : ''}
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className=" flex items-center justify-center flex-col w-full px-2 " style={{maxWidth:'800px'}} >
                                <label className="self-start">Resumo:</label>
                                <textarea name="summary" className="w-full bg-gray-200 h-40 rounded-lg dark:text-gray-900 p-1" onChange={(e) => setSummary(e.target.value)} value={summary}></textarea>
                                <button onClick={handleSave} type="button" className="p-2 w-full mt-4 rounded-lg bg-blue-900 hover:bg-blue-800 text-white">{state ? 'Atualizar' : 'Salvar'}</button>
                                {state ? <button onClick={handleClickOpen} type="button" className="p-2 w-full mt-4 rounded-lg bg-red-600 hover:bg-red-800 text-white">Deletar</button> : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            {/* <Footer/> */}
            <ToastContainer/>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Excluir Livro."}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                   <div style={{fontFamily:'Montserrat'}} className='flex gap-2'>
                        <h1 className="flex flex-col"> <p className="font-semibold">Tem certeza que deseja excluir o livro:</p> {state?.title} ?</h1>
                   </div>
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleDelete} autoFocus color="error">
                    Excluir
                </Button>
                <Button onClick={handleClose} autoFocus >
                    Cancelar
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}