import { useEffect, useRef, useState } from "react";
import Footer from "../../components/footer";
import Menu from "../../components/menu";
import useAuth from "../../hooks/useAuth";
import { toast, ToastContainer } from "react-toastify";

import styles from './styles.module.css'

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { authApi } from "../../services/api/api";
import MiniPerfil from "../../components/miniPerfil";
import MiniPerfilSkeleton from "../../components/skeletons/miniUser";


export default function Profile(){

    const api = authApi()

    const {user, theme, setUser} = useAuth()

    const [file, setfile] = useState('')

    const [image, setImage] = useState(user?.img ?? '')

    const [name, setName] = useState(user?.name ?? '')

    const [email, setEmail] = useState(user?.email ?? '')

    let inputRef = useRef<HTMLInputElement>(null)

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const [open1, setOpen1] = useState(false);

    const handleClickOpen1 = () => {
      setOpen1(true);
    };
  
    const handleClose1 = () => {
      setOpen1(false);
    };

    const [users, setUsers] = useState(null);

    async function getUsers(){
        const data = await api.selectUser()
        setUsers(data)
    }

    useEffect(()=>{
        getUsers()
    },[])

    function handleImg(){
        if(inputRef){
            inputRef.current?.click()
        }
    }

    function handleFile(e: React.ChangeEvent<HTMLInputElement>){
        const selectedFiles = e.target.files as FileList


        if(selectedFiles.length == 0) return

        if(selectedFiles?.[0].size > 1048576){
            toast.error('A imagem não pode ultrapassar 1mb.',{theme:'dark',toastId: 'password'})
            return
        }

        setfile(URL.createObjectURL(selectedFiles?.[0]))

        if(e.target.files){

            const files = e.target.files[0]

            const reader = new FileReader()
            reader.readAsBinaryString(e.target.files[0])
            reader.onload = (ev:any) => {
                setImage(`data:${files.type};base64,${btoa(ev.target.result)}`)
            }
        }
    }

    async function handleUpdate(){

        toast.loading('Atualizando...', {theme: 'dark', toastId: 'attUser'})

        const dados = {
            id:user.id,
            name:name,
            email:email,
            img:image
        }

        const data = await api.updatePerfil(dados)
        
        if(data != 500){
            handleClose()
            toast.dismiss('attUser')
            setName(data.name)
            setEmail(data.email)
            setImage(data.img)
            setUser(data)
            toast.success('Dados atualizados com sucesso',{theme:'dark',toastId: 'attDados'})
            getUsers()
        }else{
            handleClose()
            toast.dismiss('attUser')
            toast.error('Erro ao atualizar tente novamente mais tarde',{theme:'dark',toastId: 'erroAttDados'})
        }
    }

    async function handleUpdatePass(){

        toast.loading('Carregando...', {theme: 'dark', toastId: 'attPass'})

        const data = await api.resetPassword(user.email)

        if(data == 'passwords.sent'){
            toast.dismiss('attPass')
            handleClose1()
            toast.info('Acesse seu e-mail para trocar a senha', {theme: 'dark', toastId: 'att'})
        }else if(data == 'passwords.throttled'){
            handleClose1()
            toast.info('Acesse seu e-mail para trocar a senha', {theme: 'dark', toastId: 'att'})
        }
  
    }


    return(
            <div className={` w-screen bg-gray-100 ${theme} overflow-x-hidden`}>
            <Menu/>
            <div className={`content w-screen  min-h-screen flex justify-center dark:bg-slate-800 dark:text-white `}>
                <div className="w-4/5 flex justify-center p-8">
                    <div className=" w-full">
                        <div className="flex justify-center flex-col">
                            <div className="flex justify-between">
                                <h1>Perfil</h1>
                                <button onClick={handleClickOpen} className="flex items-end gap-2"><h1>Salvar</h1> <CloudUploadIcon/></button>
                            </div>
                            <hr />
                        </div>
                        <div className="mt-4 sm:p-2 flex flex-col items-center">
                            <div className={`flex ${styles.displayColumn} w-full`}>
                                <div className="flex flex-col justify-center items-center">
                                    <img src={image} alt="foto do usuario" className="w-40 rounded-full"/>
                                    <button onClick={handleImg} className="p-2 m-2 rounded bg-gray-300 hover:bg-gray-500 dark:bg-slate-600 dark:hover:bg-slate-900">Alterar foto</button>
                                    <input name="image" type="file" accept="image/*" className="hidden" ref={inputRef} onChange={(e) => {handleFile(e)}}/>
                                </div>
                                <div className="sm:mx-4 flex flex-col gap-4 ">
                                    <div className="flex flex-col gap-2 ">
                                        <label>Nome:</label>
                                        <input className="w-full md:w-96 p-1 rounded dark:text-black" value={name} onChange={(e) => setName(e.target.value)}/>
                                    </div>
                    
                                    <div className="flex flex-col gap-2 ">
                                        <label>E-mail:</label>
                                        <input className="w-full p-1 rounded dark:text-black" value={email} onChange={(e) => setEmail(e.target.value)}/>
                                    </div>
                                    <div>
                                        <button onClick={handleClickOpen1} className="w-full bg-gray-300 hover:bg-gray-500 p-2 mt-2 rounded dark:bg-slate-600 dark:hover:bg-slate-900">Alterar senha</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <h1>Membros</h1>
                            <hr />
                            <div className="flex gap-4 p-2 flex-wrap justify-center lg:justify-start">
                                { users ? users.map((user) => <MiniPerfil user={user}/>) : <MiniPerfilSkeleton/>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Atualizar dados."}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <h1>Tem certeza que deseja atualizar os dados?</h1>
                </DialogContentText>    
                </DialogContent>
                <DialogActions>
                <Button onClick={handleUpdate} autoFocus>
                    Atualizar
                </Button>
                <Button onClick={handleClose} color="error" autoFocus>
                    Cancelar
                </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={open1}
                onClose={handleClose1}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Atualizar dados."}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <h1>Tem certeza que deseja alterar a senha?</h1>
                </DialogContentText>    
                </DialogContent>
                <DialogActions>
                <Button onClick={handleUpdatePass} autoFocus>
                    Trocar senha
                </Button>
                <Button onClick={handleClose1} color="error" autoFocus>
                    Cancelar
                </Button>
                </DialogActions>
            </Dialog>

            <ToastContainer/>
            {/* <Footer/> */}
            <div className="w-full h-8 dark:bg-slate-800"></div>
        </div>
    )
}