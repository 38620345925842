import Skeleton from '@mui/material/Skeleton';

export default function SkeletonIndications(){

    const width = window.screen.width

    return (
        <div >
            <div className="w-full flex justify-center flex-col dark:bg-gray-700 p-4 my-2 rounded bg-gray-300">
                <div className="max-w-2xl flex items-center gap-2">
                    <Skeleton variant="circular" width={50} height={50} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                    <Skeleton variant="text" width={150} height={40} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                </div> 
                <div className="flex">
                    <div className="">
                        <div className="bg-gray-400" style={{width:'2px', height:'80px', margin:'5px 25px 0px 25px'}}></div>
                        <div className="bg-gray-400" style={{width:'50px', height:'2px', marginLeft:'25px', marginRight:'5px'}}></div>
                        <div className="mt-9 ml-6">
                            <button className="flex items-center gap-2">
                                <small></small>
                                <div className="text-red-700">

                                </div>
                            </button>
                        </div>
                    </div>
                    <div className="flex gap-2">
                        <Skeleton variant="rounded" width={100} height={150} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                        <div className="">
                            <Skeleton variant="text" width={width < 600 ? 100 : 500} height={40} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                            <Skeleton variant="text" width={width < 600 ? 100 : 500} height={40} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                            <Skeleton variant="text" width={width < 600 ? 100 : 500} height={85} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full flex justify-center flex-col dark:bg-gray-700 p-4 my-2 rounded bg-gray-300">
                <div className="max-w-2xl flex items-center gap-2">
                    <Skeleton variant="circular" width={50} height={50} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                    <Skeleton variant="text" width={150} height={40} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                </div> 
                <div className="flex">
                    <div className="">
                        <div className="bg-gray-400" style={{width:'2px', height:'80px', margin:'5px 25px 0px 25px'}}></div>
                        <div className="bg-gray-400" style={{width:'50px', height:'2px', marginLeft:'25px', marginRight:'5px'}}></div>
                        <div className="mt-9 ml-6">
                            <button className="flex items-center gap-2">
                                <small></small>
                                <div className="text-red-700">

                                </div>
                            </button>
                        </div>
                    </div>
                    <div className="flex gap-2">
                        <Skeleton variant="rounded" width={100} height={150} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                        <div className="">
                            <Skeleton variant="text" width={width < 600 ? 100 : 500} height={40} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                            <Skeleton variant="text" width={width < 600 ? 100 : 500} height={40} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                            <Skeleton variant="text" width={width < 600 ? 100 : 500} height={85} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full flex justify-center flex-col dark:bg-gray-700 p-4 my-2 rounded bg-gray-300">
                <div className="max-w-2xl flex items-center gap-2">
                    <Skeleton variant="circular" width={50} height={50} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                    <Skeleton variant="text" width={150} height={40} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                </div> 
                <div className="flex">
                    <div className="">
                        <div className="bg-gray-400" style={{width:'2px', height:'80px', margin:'5px 25px 0px 25px'}}></div>
                        <div className="bg-gray-400" style={{width:'50px', height:'2px', marginLeft:'25px', marginRight:'5px'}}></div>
                        <div className="mt-9 ml-6">
                            <button className="flex items-center gap-2">
                                <small></small>
                                <div className="text-red-700">

                                </div>
                            </button>
                        </div>
                    </div>
                    <div className="flex gap-2">
                        <Skeleton variant="rounded" width={100} height={150} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                        <div className="">
                            <Skeleton variant="text" width={width < 600 ? 100 : 500} height={40} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                            <Skeleton variant="text" width={width < 600 ? 100 : 500} height={40} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                            <Skeleton variant="text" width={width < 600 ? 100 : 500} height={85} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}