import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Switch from '@mui/material/Switch';

import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { authApi } from '../../services/api/api';
import { useNavigate } from 'react-router-dom';

export default function User({user, handleDelete, newUser, setNewUser}:any){
    
    const api = authApi()

    const [open, setOpen] = useState(false);

    const navigate = useNavigate()

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    async function deleteUser(){
        await handleDelete(user.id)
        handleClose()
    }

    const [file, setfile] = useState('')

    const [image, setImage] = useState(user?.img)

    const [name, setName] = useState(user?.name)

    const [email, setEmail] = useState(user?.email)

    const [password, setPassword] = useState('')

    const [checked, setChecked] = useState(user?.admin === 1 ? true : false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setChecked(event.target.checked);
    };

    const [open1, setOpen1] = useState(false);

    const handleClickOpen1 = () => {
      setOpen1(true);
    };
  
    const handleClose1 = () => {
      setOpen1(false);
    };

    let inputRef = useRef<HTMLInputElement>(null)

    function handleImg(){
        if(inputRef){
            inputRef.current?.click()
        }
    }

    function handleFile(e: React.ChangeEvent<HTMLInputElement>){
        const selectedFiles = e.target.files as FileList

        if(selectedFiles?.[0].size > 1048576){
            toast.error('A imagem não pode ultrapassar 1mb.',{theme:'dark',toastId: 'password'})
            return
        }

        setfile(URL.createObjectURL(selectedFiles?.[0]))

        if(e.target.files){

            const files = e.target.files[0]

            const reader = new FileReader()
            reader.readAsBinaryString(e.target.files[0])
            reader.onload = (ev:any) => {
                setImage(`data:${files.type};base64,${btoa(ev.target.result)}`)
            }
        }
    }

    async function handleUpdate(){

        if(name === '' || email === '' || password === ''){
            toast.error('Preencha todos os dados.',{theme:'dark',toastId: 'dados'})
            return
        }
        toast.loading('carregando.',{theme:'dark',toastId: 'loadingUser'})
        const data = {
            'name': name,
            'email': email,
            'password': password,
            'admin': checked,
            'img': image,
            'id': user.id
        }
        const response = await api.updateUser(data)

        toast.dismiss('loadingUser')
        if(response === 200){
            setNewUser(!newUser)
            handleClose1()
            toast.success('Usuário cadastrado com sucesso.',{theme:'dark',toastId: 'succesUser'})
        }else{
            toast.error('Ocorreu algum erro tente novamente mais tarde.',{theme:'dark',toastId: 'errorUser'})
        }
        
    }

    let emailDisplay = user.email.split(' ')
    if(emailDisplay[0].length > 17 && window.screen.width < 500){
        emailDisplay = emailDisplay[0].substring(0,17) + '...'
    }else{
        emailDisplay = emailDisplay[0]
    }

    return (
        <div className="w-full dark:bg-gray-700 bg-gray-300 rounded my-2 p-2">
            <div className="flex sm:items-end justify-between flex-col">
                <div className="flex items-center flex-col sm:flex-row w-full">
                    <div className="m-4 w-12 sm:w-20">
                        <img src={`${user.img}`} alt="foto do usuario" className='rounded-full' />
                    </div>
                    <div className='w-full sm:w-96'>
                        <h1>{user.name}</h1>
                        <h1><span className="text-black font-semibold text-sm">Administrador:</span> <span className='text-sm'>{user.admin === 1 ? 'Sim' : 'Não'}</span></h1>
                        <h1><span className="text-black font-semibold text-sm">E-mail:</span> <span className='text-sm'>{emailDisplay}</span></h1>
                    </div>
                </div>
                <div className="flex gap-4 p-2">
                    <button onClick={() => navigate('/usuario', {state: user})}><RemoveRedEyeIcon color='info'/></button>
                    <button onClick={handleClickOpen1}><FaceRetouchingNaturalIcon color='success'/></button>
                    <button onClick={handleClickOpen}><DeleteForeverIcon color='error'/></button>
                </div>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Tem certeza que desja excluir usuário?"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">

                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={deleteUser} autoFocus>
                    <span className='text-red-500'>Sim</span>
                </Button>
                <Button onClick={handleClose} autoFocus>
                    Cancelar
                </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={open1}
                onClose={handleClose1}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-update">
                {"Editar Usuário."}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                   <div style={{fontFamily:'Montserrat'}} className='flex gap-2 items-center flex-col w-80'>
                        <div onClick={handleImg} style={{width:'100px', height:'100px'}} className="rounded-full bg-gray-300 flex justify-center items-center cursor-pointer">
                            <span className={`${image ? 'hidden' : ''}`}>Foto</span>
                            <img className={`${image ? '' : 'hidden'}`} src={image} alt="" style={{width:'100px', height:'100px', borderRadius:'50%'}}/>
                            <input name="image" type="file" accept="image/*" className="hidden" ref={inputRef} onChange={(e) => {handleFile(e)}}/>
                        </div> 
                        <div className="w-full">
                            <div className="flex flex-col">
                                <label htmlFor="">Nome:</label>
                                <input value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder="Nome Completo" className="bg-gray-200 rounded p-1"/>
                            </div>
                            <div className="flex flex-col my-2">
                                <label htmlFor="">E-mail:</label>
                                <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="E-mail" className="bg-gray-200 rounded p-1"/>
                            </div>
                            <div className="flex flex-col">
                                <label htmlFor="">Senha:</label>
                                <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Senha" className="bg-gray-200 rounded p-1"/>
                            </div>
                            <div className="flex items-center">
                                <label htmlFor="">Administrador:</label>
                                <Switch
                                checked={checked}
                                onChange={handleChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </div>
                        </div>
                   </div>
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleUpdate} autoFocus>
                    Salvar
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}