import { Link, useLocation } from "react-router-dom";
import { FilePlus, Users } from "../../assets/icons/icons";
import styles from '../menu/style.module.css'

import { toast, ToastContainer } from 'react-toastify';

import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { authApi } from "../../services/api/api";
import { useState } from "react";
import useAuth from "../../hooks/useAuth";

export default function MenuAdmin(){

    const { user } = useAuth()

    const api = authApi()

    const { pathname } = useLocation()

    const [open, setOpen] = useState(false);

    const [start, setStart] = useState(user?.start ?? '');

    const [end, setEnd] = useState(user?.end ?? '');

    const handleClickOpen = () => {
        setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    async function handlefreePosts(){

        if(start == '' || end == ''){
            toast.error('É necessário adicionar data de inicio e fim.',{theme:'dark',toastId: 'error'})
        }
        const data = {
            'start': start,
            'end': end
        }
        const response = await api.liberarPosts(data)

        if(response == 200){
            toast.success('Data criada com sucesso.',{theme:'dark',toastId: 'successPost'})
            handleClose()
            return
        }
        toast.error('Não foi possivel criar a data',{theme:'dark',toastId: 'errorPost'})
        handleClose()
    }

    return (
        <div>
            <Link to='/usuarios' className={`hover:text-sky-600 hover:bg-sky-300 hover:rounded-b-md flex items-end p-5 ${pathname === '/usuarios' ? styles.activeLeft : ''}`}>
                {Users} <span className="h-5 ml-4">Usuários</span>
            </Link>
            <Link to='/adicionar-livro' className={`hover:text-sky-600 hover:bg-sky-300 hover:rounded-b-md flex items-end p-5 ${pathname === '/adicionar-livro' ? styles.activeLeft : ''}`}>
                {FilePlus} <span className="h-5 ml-4">Adicionar Livro</span>
            </Link>
            <button onClick={handleClickOpen} className={`hover:text-sky-600 hover:bg-sky-300 hover:rounded-b-md flex items-end p-5 w-full`}>
                <PlaylistAddCheckIcon/> <span className="h-5 ml-4">Liberar Posts</span>
            </button>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Liberar data para publicação."}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                   <div style={{fontFamily:'Montserrat'}} className='flex gap-2 flex-col '>
                        <div className="flex flex-col">
                            <label className="text-gray-900">Data de início</label>
                            <input className="w-40" type="date" value={start} onChange={(e) => setStart(e.target.value)}/>
                        </div>
                        <div className="flex flex-col">
                            <label className="text-gray-900">Data de término</label>
                            <input className="w-40" type="date" value={end} onChange={(e) => setEnd(e.target.value)}/>
                        </div>
                   </div>
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button color="error" autoFocus onClick={handleClose}>
                    Cancelar
                </Button>
                <Button onClick={handlefreePosts} autoFocus>
                    Salvar
                </Button>
                </DialogActions>
            </Dialog>
            <ToastContainer/>
        </div>
    )
}