import Skeleton from '@mui/material/Skeleton';

export default function MiniPerfilSkeleton(){

    const arr = [1,2,3,4,5]

    return (
        <div className='flex gap-4'>
        {arr.map(() => 
            <div>
                <Skeleton variant="rectangular" width={50} height={50} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                <Skeleton variant="text" width={50} height={20} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
            </div>
        )}
        </div>
    )
}