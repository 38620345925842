import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import { useNavigate } from 'react-router-dom';

export default function LastBooksHome({item}:any){
    
    const navigate = useNavigate()

    return (
        <div onClick={() => navigate('/biblioteca', {state:item?.title})} key={item} style={{maxWidth:'350px', height:'170px'}} className='w-full overflow-hidden bg-gray-200 rounded flex p-2 gap-2 cursor-pointer hover:shadow-2xl dark:hover:border-sky-500 border-2'>
            <div className='w-full flex'>
                <div className='' style={{width:'110px', height:'150px'}}>
                    <img src={item?.img} alt="imagem do livro"  className="rounded" style={{maxWidth:'110px', maxHeight:'150px'}}/>
                </div>
                <div>
                    <div className="px-2 overflow-hidden" style={{maxWidth:'230px', maxHeight:'80px'}}>
                        <h3 className="flex flex-col whitespace-nowrap"><strong title={item?.title} >Titulo:</strong><span style={{textTransform:'capitalize'}}>{item?.title}</span></h3>
                    </div>
                    <div className="px-2 overflow-hidden" style={{maxWidth:'200px'}}>
                        <h3 className="flex gap-2 whitespace-nowrap"><strong title={item?.autor} >Autor:</strong><span style={{textTransform:'capitalize'}}>{item?.autor}</span></h3>
                    </div>
                    <div className="px-2">
                        <h3 className="flex gap-2"><strong>Editora:</strong><span style={{textTransform:'capitalize'}}>{item?.editora}</span></h3>
                    </div>
                    <div className='flex items-end px-2 gap-1'>
                        <span className='text-blue-700'>{item.rating > 0 ? <ThumbUpAltIcon/> : <ThumbUpOffAltIcon/>}</span>
                        <span>{item?.rating}</span>
                    </div>
                </div>
            </div>
    </div>
    )
}