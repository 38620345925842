import Skeleton from '@mui/material/Skeleton';

export default function SkeletonUsers(){

    const width = window.screen.width

    return(
        <div className="w-full dark:bg-gray-700 bg-gray-300 rounded my-2 p-2">
            <div className="flex items-start sm:items-end justify-between flex-col sm:flex-row">
                <div className="flex items-center" style={{flexDirection:width < 500 ? 'column' : 'row'}}>
                    <div className="m-4 w-12">
                        <Skeleton variant="circular" width={50} height={50} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                    </div>
                    <div>
                        <Skeleton variant="text" width={width < 600 ? 200 : 450} height={40} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                        <Skeleton variant="text" width={width < 600 ? 200 : 450} height={40} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                        <Skeleton variant="text" width={width < 600 ? 200 : 450} height={40} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                    </div>
                </div>
                <div className="flex gap-4 ">
                    <Skeleton variant="text" width={80} height={40} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                    <Skeleton variant="text" width={80} height={40} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                </div>
            </div>
        </div>
    )
}
