import Skeleton from '@mui/material/Skeleton';

export default function ActualBook(){
    return (
        <div className="flex dark:bg-gray-600 bg-white p-6 rounded shadow gap-4">
            <div>
                <Skeleton variant="rounded" width={150} height={200} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
            </div>
            <div className="flex flex-col justify-between">
                <div className="py-4">
                    <div>
                        <Skeleton variant="text" width={200} height={40} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                        <Skeleton variant="text" width={200} height={40}  animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                    </div>
                    <div className="mt-2 flex flex-col gap-2">
                        <Skeleton variant="text" width={200} height={40}  animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                        <Skeleton variant="text" width={200} height={40}  animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                    </div>
                </div>
            </div>
        </div>
    )
}
