import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Menu from "../../components/menu";
import Footer from "../../components/footer";
import useAuth from "../../hooks/useAuth";

import { useLocation, useNavigate } from "react-router-dom";
import { FullHeart, Heart } from "../../assets/icons/icons";
import { useState } from "react";

import { authApi } from "../../services/api/api"

import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import TextEditor from "../../components/textEditor";


export default function Indicacacao({item}:any){

    const { user, theme }  = useAuth()

    const navigate = useNavigate()

    const data = useLocation()

    const api = authApi()

    const [text, setText] = useState('')


    async function handleIndication(){

        if(text === ''){
            toast.error('Insira um comentário.',{theme:'dark',toastId: 'errorText'})
            return
        }

        toast.loading('Carregado.',{theme:'dark',toastId: 'loading'})

        const indication = {
            text: text,
            user_id: user?.id,
            book_id: data.state.id
        }

        const response = await api.setIndication(indication)

        if(response === 200){
            
            toast.dismiss('loading')

            navigate('/indicacoes', {state: true})
        }else{
            toast.error('Ocorreu um erro, tente novamente mais tarde.',{theme:'dark',toastId: 'error500'})
        }

    }

    return (
        <div className={`w-screen h-screen bg-gray-100 ${theme}`}>
            <Menu/>
            <div className="w-screen dark:bg-slate-800 bg-gray-100">
                <div className="w-full flex justify-center m-auto dark:bg-slate-800 dark:text-white">
                    <div className="w-full  p-8 min-h-screen ">
                        <div className="w-full ">
                            <h1>Indicação</h1>
                            <hr className="border-gray-200 border-1 rounded w-full"/>
                        </div>
                        <div className="flex justify-center w-full mt-2">
                            <div className="bg-white w-full rounded dark:bg-gray-600">
                                <div className="p-6 flex justify-center gap-5 flex-col sm:flex-row">
                                    <div>
                                        <img src={data.state.img} alt="capa do livro" width={250} height={350}/>
                                        <div className="flex gap-1 items-end">
                                            <small className="mt-1">{data.state.rating}</small>
                                            <span className="text-blue-700">{ data.state.rating > 0 ? <ThumbUpAltIcon/> : <ThumbUpOffAltIcon/>}</span>
                                        </div>
                                    </div>
                                    <div className="flex flex-col justify-between w-full">
                                        <div className="">
                                            <div style={{textTransform:'capitalize'}} className="font-semibold text-gray-900">
                                                <h1 className="font-semibold">{data.state.title}</h1>
                                                <h5>{data.state.autor}</h5>
                                            </div>
                                            <div className="flex gap-1 flex-col overflow-auto" style={{maxHeight:'240px'}}>
                                                <div>
                                                    {data.state.summary}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-4">
                                    <h1 className="text-gray-400">Comentário</h1>
                                    <hr className="w-full border-gray-300"/>
                                    {/* <textarea onChange={(e) => setText(e.target.value)} className="bg-gray-200 rounded w-full mt-4 h-20 text-black"></textarea> */}
                                    <div className=" text-black bg-white">
                                        <TextEditor setText={setText}/>
                                    </div>
                                    <div className="">
                                        <button onClick={handleIndication} className="p-2 rounded w-full text-white bg-blue-600 hover:bg-blue-500 dark:bg-blue-900 dark:hover:bg-blue-600">Indicar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer/>
            {/* <Footer/> */}
            <div className="w-full h-8 dark:bg-slate-800"></div>
        </div>
    )
}