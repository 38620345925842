import Skeleton from '@mui/material/Skeleton';

export default function SkeletonBiblioteca(){

    const arrayItems = [1,2,3,4,5,6,7,8]

    const width = window.screen.width

    return (

        <div className='flex gap-2 w-full flex-wrap justify-center'>
            {arrayItems.map((item) => <div className={`${width < 500 ? 'w-full' : ''}`} key={item}>
                <div style={{width:width > 500 ? '250px' : '100%', height:width > 500 ? '450px' : '200px'}} className='bg-gray-300 rounded flex justify-center items-center flex-col py-4'>
                    <Skeleton variant="rounded" width={200} height={250} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                    <Skeleton variant="text" width={200} height={40} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                    <Skeleton variant="text" width={200} height={40} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>

                    <div className='flex justify-between gap-2'>
                        <Skeleton variant="text" width={60} height={50} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                        <Skeleton variant="text" width={60} height={50} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                        <Skeleton variant="text" width={60} height={50} animation="pulse" sx={{ bgcolor: 'grey.500' }}/>
                    </div>
                </div>

            </div>)}
        </div>

    )
}