import Footer from "../../components/footer"
import Menu from "../../components/menu"
import useAuth from "../../hooks/useAuth"

import { keepPreviousData, useInfiniteQuery } from "@tanstack/react-query"
import { authApi } from "../../services/api/api"
import { ToastContainer, toast } from "react-toastify"
import Indications from "../../components/indications"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

import FilterAltIcon from '@mui/icons-material/FilterAlt';


import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import SkeletonIndications from "../../components/skeletons/indications"

import { useInView } from 'react-intersection-observer';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';


export default function Indicacoes(){

    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');

    const api = authApi()

    const [users, setUsers] = useState([]);

    const [label, setLabel] = useState(false);

    useEffect(()=>{
        async function getUsers(){
            const data = await api.selectUser()
            setLabel(true)
            setUsers(data)
        }
        getUsers()
    },[])

    const [open, setOpen] = useState(false);

    const handleClickOpen = async() => {
        setOpen(true);
    };
    
    const handleClose = () => {
      setOpen(false);
    };

    const { ref, inView } = useInView();

    const { user, theme }  = useAuth()

    const [comment, setComment] = useState(false)

    const [start, setstart] = useState('')
    const [end, setEnd] = useState('')

    const [filtro, setFiltro] = useState(null)

    const att = useLocation()

    const [atualizar, setAtualizar] = useState(false)

    const [lastPage, setlastPage] = useState(false)

    const getData = async({pageParam}) => {

        setlastPage(pageParam.last_pages)
        
        const data = await api.getIndications(pageParam.nextPage,3, filtro)

        return data 
    }


    const {data, fetchNextPage, hasNextPage, isFetchingNextPage} = useInfiniteQuery({
        queryKey: ['indications',comment, att.state, atualizar, filtro],
        queryFn: getData,
        // placeholderData:keepPreviousData, 
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPages) => { 

            const nextPage = allPages.length + 1 

            let last_pages = false

            if(nextPage >= lastPage.last_page){
                last_pages = true
            }else{
                last_pages = false
            }

            return {nextPage, last_pages}

        }
    })

    useEffect(() => {
        
        if(inView && !lastPage){
            fetchNextPage()
        }
    }, [inView, fetchNextPage, lastPage, isFetchingNextPage])


    const handleComment = async(data:any) =>{
        const response = await api.setComment(data)

        if(response === 200){
            setComment(!comment)
        }

    }

    function handleFilter(){
        setFiltro({start,end,inputValue})
        handleClose()
    }


    return(
        <div className={`main w-screen bg-gray-100 ${theme} overflow-x-hidden`}>
            <Menu/>
            <div className="content w-screen min-h-screen flex justify-center dark:bg-slate-800 dark:text-white">
                <div className="sm:w-4/5 w-full flex justify-center p-8">
                    <div className="w-full">
                        <div className="w-full flex justify-between">
                            <h1>Indicações</h1>
                            <button onClick={handleClickOpen}><FilterAltIcon/></button>
                        </div>
                            <hr className="border-gray-200 border-1 "/>
                        {data?.pages ? (data.pages.map((items) => items.data.map((item) => <Indications atualizar={atualizar} setAtualizar={setAtualizar} handleComment={handleComment} key={item.id} item={item}/>)))
                        : <SkeletonIndications/>}
                        {isFetchingNextPage ? <SkeletonIndications/> : ''}
                        <span ref={ref} className=""></span>
                    </div>
                </div>
            </div>
            <ToastContainer/>
            {/* <Footer/> */}

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Filtrar."}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div className="p-4">
                    <Autocomplete
                        value={value}
                        onChange={(event: any, newValue: string | null) => {
                        setValue(newValue);
                        
                        }}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                        }}
                        id="controllable-states-demo"
                        options={users?.map((item)=> item.email)}
                        sx={{ width: 200 }}
                        renderInput={(params) => <TextField {...params} label={label ? 'Usuários' : <CircularProgress size={20}/>}/>}
                    />
                    </div>
                    <div className="p-4">
                        <div className="flex flex-col">
                            <label>Data inicial:</label>
                            <input type="date"  className="p-1 border rounded border-gray-400" onChange={(e) => setstart(e.target.value)}/>
                        </div>
                        <div className="flex flex-col mt-4">
                            <label>Data final:</label>
                            <input type="date"  className="p-1 border rounded border-gray-400" onChange={(e) => setEnd(e.target.value)}/>
                        </div>
                    </div>
                </DialogContentText>    
                </DialogContent>
                <DialogActions>
                <Button autoFocus onClick={handleFilter}>
                    Filtrar
                </Button>
                <Button onClick={handleClose} color="error" autoFocus>
                    Cancelar
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}